import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-screen-text',
  templateUrl: './screen-text.component.html',
  styleUrls: ['./screen-text.component.css']
})

export class ScreenTextComponent implements OnInit {

  constructor() { }

  @Output() checkPage = new EventEmitter();
  @Input() info: any;
  @Input() screen: any;
  @Input() tamList: any;

  textButton: string = 'Próximo';

  ngOnInit(): void {
    // console.log(this.info)

    if (this.screen == this.tamList - 1) {
      this.textButton = 'Solucionar caso';
    }
    else {
      this.textButton = 'Próximo';
    }

  }

  pageNext() {
    if (this.screen == (this.tamList - 1)) {
      this.checkPage.emit('FINISH');
    }
    else
      this.checkPage.emit(null);
  }

}
