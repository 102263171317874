import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Case } from '../models/case';
import { Patient } from '../models/patient';
import { CaseService } from '../service/case.service';
import { PatientService } from '../service/patient.service';

@Component({
  selector: 'app-case',
  templateUrl: './case.component.html',
  styleUrls: ['./case.component.css']
})

export class CaseComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private caseService: CaseService,
    private patientService: PatientService
  ) {
    this.router.events.subscribe((ev) => {
      this.case_id = this.route.snapshot.paramMap.get('id');
    })
  }

  loading: boolean = true;

  url
  case_id;

  case: Case;
  pacient: Patient

  imgtext = [
    { img: 'https://images.unsplash.com/photo-1610294381328-9bcf7764010f?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80' },
    { img: 'https://images.unsplash.com/photo-1608833970687-99bc4f54898d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80' },
    { img: 'https://images.unsplash.com/photo-1610132366635-2d140c69a3ae?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=671&q=80' },
    { img: 'https://images.unsplash.com/photo-1609999962569-f0f757358cf7?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=80' },
    { img: 'https://images.unsplash.com/photo-1609533676311-0da452fda608?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=701&q=80' },
    { img: 'https://images.unsplash.com/photo-1608415295464-b5de23d21f59?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80' },
    { img: 'https://images.unsplash.com/photo-1610294381328-9bcf7764010f?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80' },
    { img: 'https://images.unsplash.com/photo-1608833970687-99bc4f54898d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80' },
    { img: 'https://images.unsplash.com/photo-1609533676311-0da452fda608?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=701&q=80' },
    { img: 'https://images.unsplash.com/photo-1610132366635-2d140c69a3ae?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=671&q=80' },
  ]

  ngOnInit(): void {

    if (this.case_id != null) {
      this.getInfoCase()
    }
    else {
      this.router.navigate(['/cases'])
    }
  }

  getInfoCase() {
    this.loading = true;
    this.caseService.getCaseId(this.case_id).subscribe(data => {
      this.case = data;
      this.pacient = this.case.patient
      this.loading = false;
    }, error => {
      const e = error?.error?.detail
      if (e == "Caso inválido") {
        this.router.navigate(['/cases'])
      }
    })
  }

}
