<div style="width: 100%; height: auto; min-height: calc(100vh - 250px); background-color: #13293a;">
  <div class="screen">

    <div class="coll1 div-border">
      <div style="width: auto; display: flex; align-items: center;">
        <div class="div-img">
          <img [src]=" pacient?.image == null? './../assets/image/setup-orange.jpg' : pacient.image">
        </div>
        <div
          style="display: flex; flex-direction: column; font-size: calc(13px + 0.2vw); line-height: 1.5; margin-left: 15px;">
          <div style="font-weight: 600;">
            {{ pacient?.name }}
          </div>
          {{ pacient?.height }}m e {{ pacient?.weight }}kg <br>
          {{ pacient?.age }} anos <br>
          Sexo: {{ pacient?.gender }} <br>
        </div>
      </div>

      <div class="div-menu" style="margin: 30px 0 5px;" routerLink="/case/{{case_id}}"
        [ngClass]="{ 'div-select' : screenSelect == 0 }">
        Queixa
      </div>

      <div *ngFor="let info of info_case; let i = index" style="width: 100%;">
        <div class="div-menu" (click)="checkType(info, i)" [ngClass]="{ 'div-select' : position == i }">

          {{ info.title }}
        </div>
      </div>

    </div>

    <div class="coll2 div-border">

      <div class="loading" *ngIf="loading">
        <div>
          <mat-spinner diameter="50" strokeWidth="7"></mat-spinner>
        </div>
      </div>
      <div style="width: 100%; height: 100%;" *ngIf="!loading">
        <div class="title" *ngIf="typePage == null && screenSelect != 8">
          Nenhum tipo para esse caso.
        </div>
        <app-screen-text *ngIf="typePage == 'TEXT'" (checkPage)="next($event)" [info]="infoSelect"
          [tamList]="info_case.length" [screen]="position"></app-screen-text>

        <app-screen-table *ngIf="typePage == 'TABLE'" (checkPage)="next($event)" [info]="infoSelect"
          [tamList]="info_case.length" [screen]="position"></app-screen-table>

        <app-screen-table-details *ngIf="typePage == 'CUSTOM'" (checkPage)="next($event)" [info]="infoSelect"
          [tamList]="info_case.length" [screen]="position"></app-screen-table-details>

        <app-screen-image *ngIf="typePage == 'IMAGE'" (checkPage)="next($event)" [info]="infoSelect"
          [tamList]="info_case.length" [screen]="position"></app-screen-image>

        <app-solve-case *ngIf="screenSelect == 8; " [case]="case"></app-solve-case>
        <!-- <app-complaint          *ngIf="screenSelect == 0"  (checkPage)="screenSelect = 1"> </app-complaint>
                    <app-food-recall        *ngIf="screenSelect == 1"  (checkPage)="screenSelect = 2"></app-food-recall>
                    <app-biochemistry       *ngIf="screenSelect == 2"  (checkPage)="screenSelect = 3"></app-biochemistry>
                    <app-body-composition   *ngIf="screenSelect == 3"  (checkPage)="screenSelect = 4"></app-body-composition>
                    <app-physical-exam      *ngIf="screenSelect == 4"  (checkPage)="screenSelect = 5"></app-physical-exam>
                    <app-medicine           *ngIf="screenSelect == 5"  (checkPage)="screenSelect = 6"></app-medicine>
                    <app-exercise           *ngIf="screenSelect == 6"  (checkPage)="screenSelect = 7"></app-exercise>
                    <app-sleep              *ngIf="screenSelect == 7"  (checkPage)="screenSelect = 8"></app-sleep> -->
      </div>
    </div>
  </div>
</div>



<!-- <div style="width: 100%; background-color: #0a1e2d;">
    <div class="footer">
        <div class="coll-externo">
            <div class="coll-interno">
                <a href=""> Busca </a>
                <a href=""> Casos </a>
                <a href=""> Mande seu caso clínico </a>
            </div>
            <div class="coll-interno">
                <a href=""> Perfil </a>
                <a href=""> Casos feitos</a>
            </div>
            <div class="coll-interno">
                <a href=""> Dados cadastrais </a>
                <a href=""> Configurações </a>
            </div>
        </div>
        <div class="coll-social">
            <img src="../../assets/icons/facebook.png" class="img-social">
            <img src="../../assets/icons/insta.png" class="img-social">
            <img src="../../assets/icons/linkedin.png" class="img-social">
        </div>
    </div>
</div> -->
