import { Component, OnInit } from '@angular/core';
import { Md5 } from "md5-typescript";
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-confirm-password-change',
  templateUrl: './confirm-password-change.component.html',
  styleUrls: ['./confirm-password-change.component.css']
})

export class ConfirmPasswordChangeComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
    try {
      this.route.queryParams.subscribe(params => {
        this.email = params.email;
        this.hash = params.hash;
      });
    } catch {
      this.router.navigate([''])
    }
  }

  email;
  hash;
  password: string = "";
  confirm_password: string = "";
  text_button: string = "CONFIRMAR";

  ngOnInit(): void {

  }

  changePassword() {

  }

  login() {
    this.router.navigate(['/login']);
  }

}
