<div class="screen">
  <div class="div-background">

    <img src="../../assets/icons/seta_direita.png" class="button_scroll_top" (click)="navigateTop()"
      style="transform: rotate(-90deg);">

    <div class="div-text">
      <div style="color: #ef6442; font-size: calc(20px + 0.4vw); font-weight: 600; margin-bottom: 20px;">
        Mande seu caso clínico
      </div>
      <div>
        <button mat-button class class="button" style="background-color: #ef6442;" (click)="send()">
          Enviar
        </button>
      </div>
    </div>

    <div style="max-width: 570px; font-size: calc(13px + 0.3vw);">
      Estamos construindo esse espaço a várias mãos, portanto fique a vontade para compartilhar também os seus casos
      clínicos
      conosco. Pedimos só que preservem informações que de alguma maneira identifiquem os seus pacientes.
    </div>

    <div style="height: 20px;"></div>

    <mat-checkbox class="checkbox" [(ngModel)]="checked">
      Confirmo que todas as informações não são verdadeiras.
    </mat-checkbox> <br>

    <div style="width: 100%; text-align: center; height: 20px; color: #DC4E4E; font-size: 15px;">
      {{ errorMessage }}
    </div>
    <!-- <mat-checkbox [(ngModel)]="checked">Confirmo que todas as informações são verdadeiras.</mat-checkbox> -->

    <div style="height: 20px;"></div>
    <div class="div-for">
      <div *ngFor="let obj of list_case; let i = index" style="width: 49%; display: flex; flex-direction: column;">

        <div style=" margin: 25px 0 20px; color: #ef6442; font-size: calc(13px + 0.3vw);">
          {{obj.title}}
        </div>
        <div class="description">

          <ng-scrollbar class="my-scrollbar">
            <textarea style="width: 95%;" rows="10" [(ngModel)]="obj.value"
              placeholder="Digite sua resposta..."></textarea>

          </ng-scrollbar>
        </div>

      </div>

      <div style="width: 49%; display: flex; flex-direction: column;">

        <div style="margin: 20px 0 15px; color: #ef6442; font-size: calc(13px + 0.3vw);
                    display: flex; align-items: center; justify-content: space-between;">

          Adicionar imagem ou arquivo
          <img src="../../assets/icons/adicionar.png" width="30px" style="cursor: pointer;" (click)="filePages.click()">
        </div>

        <input #filePages type="file" accept="image/*" multiple="multiple" style="display: none;"
          (change)="onFileChanged($event)">

        <div ng-scrollbar class="description">
          <ng-scrollbar class="my-scrollbar">
            <div *ngFor="let obj of imagesName; let i = index" style="width: 90%; display: flex;"
              [ngStyle]="{'margin': i == 0 ? '0 0 20px 0' : '20px 0 0 0'}">
              <div class="breakLine"> {{ obj }} </div>
              <div style="margin: 0 15px; cursor: pointer;" (click)="remove(i)">
                X
              </div>
            </div>
          </ng-scrollbar>

        </div>

      </div>
    </div>
  </div>

</div>

<!-- <div style="width: 100%; background-color: #0a1e2d;">
    <div class="footer">
        <div class="coll-externo">
            <div class="coll-interno">
                <a href=""> Busca </a>
                <a href=""> Casos </a>
                <a href=""> Mande seu caso clínico </a>
            </div>
            <div class="coll-interno">
                <a href=""> Perfil </a>
                <a href=""> Casos feitos</a>
            </div>
            <div class="coll-interno">
                <a href=""> Dados cadastrais </a>
                <a href=""> Configurações </a>
            </div>
        </div>
        <div class="coll-social">
            <img src="../../assets/icons/facebook.png" class="img-social">
            <img src="../../assets/icons/insta.png" class="img-social">
            <img src="../../assets/icons/linkedin.png" class="img-social">
        </div>
    </div>
</div> -->
