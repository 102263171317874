import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ConstantsService } from '../service/constants.service';
import { StorageService } from '../service/storage.service';
import { UserService } from '../service/user.service';
import { EditPasswordComponent } from './edit-password/edit-password.component';
import { MedalsComponent } from './medals/medals.component';
import { RankingComponent } from './ranking/ranking.component';
import { RegistrationDataComponent } from './registration-data/registration-data.component';
import { SettingsComponent } from './settings/settings.component';
import { CaseService } from '../service/case.service';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})

export class UserProfileComponent implements OnInit {

  constructor(private modalService: MDBModalService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private storageService: StorageService,
    private constantsService: ConstantsService,
    private caseService: CaseService
  ) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.user_id = this.route.snapshot.paramMap.get('id');
      }
    })
  }

  modalRef: MDBModalRef;
  modalRanking: MDBModalRef;

  user_id: any = null;
  teste = [1, 2, 3]

  list_medals = [{ img: '../../assets/icons/medalha2.png', name: 'Nutrology Academy', year: '2020' },
  { img: '../../assets/icons/medalha1.png', name: 'Nutrology Academy', year: '2019' },
  { img: '../../assets/icons/medalha2.png', name: 'Nutrology Academy', year: '2018' },
  { img: '../../assets/icons/medalha1.png', name: 'Nutrology Academy', year: '2017' },
  { img: '../../assets/icons/medalha2.png', name: 'Nutrology Academy', year: '2016' },
  ]

  list_cases = []

  lists_recommended = []

  user;
  loading: boolean = false;
  score: number = 0;

  ngOnInit(): void {
    if (this.user_id != null) this.getUserId()
    else this.getUser()

    this.getRecommended()
  }

  getUser() {
    this.loading = true;
    this.userService.getUser().subscribe(data => {
      this.user = data
      this.getRankingCases()
      this.loading = false;
    },
      error => {
        this.loading = false;
      })
  }

  getUserId() {
    this.loading = true;
    this.userService.getUserId(this.user_id).subscribe(data => {
      this.user = data
      this.getRankingCases()
      this.loading = false;
    },
      error => {
        this.loading = false;
      })
  }

  getRankingCases() {
    this.caseService.getRankingCases(this.user.id).subscribe(data => {
      this.list_cases = data.results;
    })
  }

  openSocial(url) {
    if (url != null) {
      if (url.substring(0, 4) != 'http') {
        window.open(`http://${url}`);
      }
      else {
        window.open(url);
      }
    }
  }


  getRecommended() {
    this.constantsService.getRecommended().subscribe(data => {

      data.results.forEach((element, index) => {
        if (index < 2) {
          this.lists_recommended.push(element);
        }
      });

    },
      error => { console.log(error) })
  }

  ordenarList(a, b) {
    if (a.order < b.order)
      return -1;
    if (a.order > b.order)
      return 1;
    return 0;
  }

  logoff() {
    this.storageService.logout()
    this.router.navigate(['/']);
  }

  openMedals() {
    this.modalRef = this.modalService.show(MedalsComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
      data: {
        medals: this.list_medals
      }
    })
  }

  openSettings() {
    this.modalRef = this.modalService.show(SettingsComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
      data: {
        id: this.user.id,
        commercial: this.user?.allow_commercial_email != null ? this.user?.allow_commercial_email : false,
        notification: this.user?.allow_notification != null ? this.user?.allow_notification : false,
        ranking: this.user?.overall_ranking != null ? this.user?.overall_ranking : false,
      }
    })
  }

  openRegistrationData() {
    this.modalRef = this.modalService.show(RegistrationDataComponent, {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-lg',
      containerClass: '',
      data: {
        user: this.user
      }
    })
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "edit") {
        this.openEditPassword()
      }
      else if (result == "loading") {
        this.getUser()
      }
    });
  }

  openEditPassword() {
    this.modalRef = this.modalService.show(EditPasswordComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
      data: {
        user: this.user
      }
    })
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "edit") {
        this.getUser()
      }
    });
  }

  openRanking() {
    this.modalRanking = this.modalService.show(RankingComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-lg',
      containerClass: '',
    })
    this.modalRanking.content.action.subscribe((result: any) => {
      if (result != null) {
        this.user_id = result
        this.getUserId()
      }
    });
  }

}
