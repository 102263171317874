<div class="screen">
  <div
    style="width: 100%; height: 50px; display: flex; align-items: center;  justify-content: space-between; color: #ef6442; font-size: calc(22px + 0.4vw); margin: 0; font-weight: 600; margin-bottom: 20px;">
    {{ info?.title }}
    <!-- <div style="font-size: 17px; color: white;">
            <span> <img src="../../../assets/image/time.png" width="25px" style="margin: 0 10px 5px 0;"> </span> 3:45
        </div> -->
  </div>
  <div *ngIf="info?.content_case != null" style="width: 100%; max-width: 630px; max-height: 400px; padding: 0 10px 0 0;
      overflow-y: auto; margin: auto 0; font-size: 17px; line-height: 1.3; white-space: break-spaces;">
    {{ info?.content_case[0]?.title }}
  </div>
  <div style="width: 100%; display: flex; justify-content: flex-end; align-items: flex-end;">
    <button mat-button class="button" (click)="pageNext()">
      {{ textButton }}
    </button>
  </div>
</div>
