<div class="screen">
  <div class="box shadow">
    <h1>Nova senha</h1>

    <div style="width: 100%; display: flex; flex-direction: column; justify-content: flex-start;">
      <div class="div-input">
        <input type="text" placeholder="Digite uma nova senha" [(ngModel)]="password">
      </div>
    </div>

    <div style="width: 100%; display: flex; flex-direction: column; justify-content: flex-start;">
      <div class="div-input">
        <input type="text" placeholder="Confirmar a senha" [(ngModel)]="confirm_password">
      </div>
    </div>

    <button mat-button (click)="changePassword()"> {{ text_button }} </button>
  </div>
</div>
