import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { StorageService } from 'src/app/service/storage.service';
import { UserService } from '../../service/user.service';
@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.css']
})

export class RankingComponent implements OnInit {

  constructor(
    public modalRef: MDBModalRef,
    private router: Router,
    private userService: UserService,
    private storageService: StorageService
  ) { }

  action: Subject<any> = new Subject();

  list_state = []
  list_cities = []

  city: any = null;
  state: any = null;

  list_ranking = []

  ngOnInit(): void {
    this.list_state = this.storageService.list_state;

    this.getRankingGlobals()
  }

  selectState(obj) {
    this.userService.getCity(obj.name, obj.code).subscribe(data => {
      this.list_cities = data.cities;
      this.getRankingGlobals()
    })
  }

  openUser(id) {
    this.router.navigate([`/user/${id}`])
    this.action.next(id)
    this.modalRef.hide();
  }

  close() {
    this.modalRef.hide();
  }

  getRankingGlobals() {
    this.userService.getRankingGlobals(this.city, this.state).subscribe(data => {
      this.list_ranking = data.results;
    }, error => { console.log(error) })
  }

}
