<div class="screen">
  <div class="title">
    Alterar senha
  </div>
  <div class="icon-close" (click)="close()">
    <img src="../../../assets/image/icon-close.png" width="17">
  </div>

  <div class="div-input" *ngIf="!loading.button" style="margin-top: 45px; ">
    <div style="margin-left: 25px;">Nova senha</div>
    <input mat-input type="password" placeholder="*****" [(ngModel)]="password">
  </div>
  <div class="div-input" *ngIf="!loading.button">
    <div style="margin-left: 25px;">Senha atual</div>
    <input mat-input type="password" placeholder="*****" [(ngModel)]="oldPassword">
  </div>

  <div *ngIf="!loading.button"
    style="height: 20px; color: rgb(87, 8, 8); text-align: center; font-size: 12px; margin: 15px auto 0;">
    {{ msg_error }}
  </div>

  <span *ngIf="loading.password" class="spinner-border spinner-border-sm" style="margin: 0 auto;" role="status"
    aria-hidden="true"></span>

  <button mat-button class="bton" (click)="editPassword()" *ngIf="!loading.password && !loading.button">
    Trocar senha
  </button>

  <div class="div-input" *ngIf="loading.button">
    <div style="text-align: center;">Senha aterada com sucesso!</div>
  </div>

  <button mat-button class="bton" (click)="close()" *ngIf="!loading.password && loading.button">
    Fechar
  </button>
</div>
