<div style="height: 70px;"></div>

<div class="banner">
  <div class="div-img">
    <img src="../../assets/image/foto2.png">
  </div>

  <div class="div-info">
    <div>
      Modo aleatório
    </div>
    <button mat-button class="button" (click)="openRandom()"> Vamos lá! </button>
  </div>
</div>

<div class="screen">
  <div style="margin: 40px; text-align: center; display: flex; align-items: center;" class="max-1500">
    <div class="img-arrow" (click)="back()" *ngIf="checkButtonCategory()">
      <img src="../../assets/image/arrow.png" style="transform: rotate(180deg);">
    </div>

    <ngx-slick-carousel #slickModal="slick-carousel" [config]="slideConfigCategory">
      <div ngxSlickItem *ngFor="let category of list_categories">
        <div class="button-category to_break" (click)="selectCategory(category.title)"
          [ngClass]="{'select' : categori_select == category.title}">
          {{ category.title }}
        </div>
      </div>
    </ngx-slick-carousel>

    <div class="img-arrow" (click)="next()" *ngIf="checkButtonCategory()">
      <img src="../../assets/image/arrow.png">
    </div>
  </div>

  <div style="background-color: white;" *ngIf="lists_recommended.length > 0 && categori_select == null">

    <div style="display: flex; align-items: center; margin-top: 35px;" class="max-1500">

      <div style="color: #ef6442; font-weight: 600; font-size: calc(16px + 0.5vw);">
        Destaques
      </div>
      <button mat-button class="button button-aleatorio" (click)="openCaseRandom(null)">
        Aleatório
      </button>
    </div>

    <div style="margin: 20px 0; text-align: center; display: flex; margin-bottom: 35px;" class="max-1500">
      <div class="img-arrow arrow-top" (click)="slickModal2.slickPrev()" *ngIf="checkButtonRecommended()">
        <img src="../../assets/image/arrow.png" style="transform: rotate(180deg);">
      </div>

      <ngx-slick-carousel #slickModal2="slick-carousel" [config]="slideConfigUsers">
        <div ngxSlickItem *ngFor="let slide of lists_recommended; let i = index" style="display: flex;
                    flex-direction: column; justify-content: center; align-items: center;">

          <div class="img-users">
            <img src="{{ slide?.patient_obj?.image }}" *ngIf="slide?.patient_obj?.image != null" width="50">
            <img src="../../assets/image/foto1.png" *ngIf="slide?.patient_obj?.image == null" width="50">
          </div>
          <div class="to_break" style="margin: 20px 0 15px; font-size: 15px; color: #6a6a6a; font-weight: 400;">
            {{ slide?.title }}
          </div>
          <div>
            <button class="button button-orange" mat-button routerLink="/case/{{ slide?.id }}">
              Vamos lá!
            </button>
          </div>
        </div>
      </ngx-slick-carousel>

      <div class="img-arrow arrow-top" (click)="slickModal2.slickNext()" *ngIf="checkButtonRecommended()">
        <img src="../../assets/image/arrow.png">
      </div>
    </div>

  </div>

  <div *ngFor="let list of lists_cases; let i = index" id="case_select"
    [ngStyle]="{'background-color': i % 2 == 0 ? color[0] : color[1] }">

    <div style="display: flex; align-items: center; margin-top: 35px;" class="max-1500">

      <div style="color: #ef6442; font-weight: 600; font-size: calc(16px + 0.5vw);">
        {{list.title}}
      </div>
      <button mat-button class="button button-aleatorio" (click)="openCaseRandom(list)">
        Aleatório
      </button>
    </div>

    <div style="margin: 20px 0; text-align: center; display: flex; margin-bottom: 35px;" class="max-1500">
      <div class="img-arrow arrow-top" (click)="slickModal2.slickPrev()" *ngIf="checkButtonSlides(i)">
        <img src="../../assets/image/arrow.png" style="transform: rotate(180deg);">
      </div>

      <ngx-slick-carousel #slickModal2="slick-carousel" [config]="slideConfigUsers">
        <div ngxSlickItem *ngFor="let slide of list.slides; let i = index" style="display: flex;
                    flex-direction: column; justify-content: center; align-items: center;">

          <div class="img-users">
            <img src="{{ slide?.patient_obj?.image }}" *ngIf="slide?.patient_obj?.image != null" width="50">
            <img src="../../assets/image/foto1.png" *ngIf="slide?.patient_obj?.image == null" width="50">
          </div>
          <div class="slide_name to_break">
            {{ slide?.title }}
          </div>
          <div>
            <button class="button button-orange" mat-button routerLink="/case/{{ slide?.id }}">
              Vamos lá!
            </button>
          </div>
        </div>
      </ngx-slick-carousel>

      <div class="img-arrow arrow-top" (click)="slickModal2.slickNext()" *ngIf="checkButtonSlides(i)">
        <img src="../../assets/image/arrow.png">
      </div>
    </div>

  </div>

  <div style="width: 100%; height: 220px; display: flex; justify-content: center;
        align-items: center; text-align: center;" *ngIf="lists_cases.length == 0 && !loading">

    <div style="color: #ef6442; font-weight: 600; font-size: calc(16px + 0.5vw);">
      Nenhum caso para a categoria selecionada.
    </div>
  </div>


</div>

<div *ngIf="loading"
  style="width: 100%; height: auto; margin: 90px 0; display: flex; justify-content: center; align-items: center;">
  <span class="spinner-border spinner-border-sm" style="margin: 0 auto;" role="status" aria-hidden="true"></span>
</div>

<!-- <div style="width: 100%; background-color: #0a1e2d;">
    <div class="footer">
        <div class="coll-externo">
            <div class="coll-interno">
                <a href="/cases"> Casos </a>
                <a href="/send-case"> Mande seu caso clínico </a>
            </div>
            <div class="coll-interno">
                <a href="/user"> Perfil </a>
                <a href="/made-cases"> Casos feitos</a>
            </div>
            <div class="coll-interno">
                <a href=""> Dados cadastrais </a>
                <a href=""> Configurações </a>
            </div>
        </div>
        <div class="coll-social">
            <img src="../../assets/icons/face_laranja.png" class="img-social">
            <img src="../../assets/icons/insta_laranja.png" class="img-social">
            <img src="../../assets/icons/linkedin_laranja.png" class="img-social">
        </div>
    </div>
</div> -->
