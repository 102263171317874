import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})

export class ConstantsService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) { }

  coinsSubject = new Subject<String>();

  constants;

  getConstants(): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    };

    return this.http.get<any>(AppConfig.path + "core/constants/", options)
  }

  getFeedbacks(): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    };

    return this.http.get<any>(AppConfig.path + "core/feedbacks/", options)
  }

  getRecommended(): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('Authorization', `token ${this.storageService.getToken()}`)
    };

    return this.http.get<any>(AppConfig.path + "core/case/recommended/", options)
  }

}
