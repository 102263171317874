import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Md5 } from 'md5-typescript';
import { AuthService } from '../service/auth.service';
import { ConstantsService } from '../service/constants.service';
import { StorageService } from '../service/storage.service';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})

export class HomepageComponent implements OnInit {

  constructor(
    private router: Router,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private storageService: StorageService,
  ) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.url = this.router.url;
        if (this.url.substring(0, 9) == "/login") {
          this.page = 1;
        }
        else if (this.url.substring(0, 9) == "/register") {
          this.page = 2;
        }
      }
    })
  }

  url;

  teste = [
    { img: "/assets/icons/icone1.png", text: "Todos os alunos Nutrology Academy receberão um login e senha de acesso a plataforma de casos." },
    { img: "/assets/icons/icone2.png", text: "Após o cadastro, você terá a sua disposição diversos casos clínicos de Nutrologia para estudar e aplicar o seu conhecimento adquirido nos cursos." },
    { img: "../../assets/icons/icone3.png", text: "A todo momento você poderá acompanhar o seu progresso no painel. Onde poderá ver a sua pontuação, posição no ranking e histórico de casos envolvidos. " },
    { img: "../../assets/icons/icone4.png", text: "Você também pode compartilhar casos clínicos com os outros estudantes da Nutrology. Essa ferramenta foi feita para ser criada e usada por todos." }]

  page = 1;

  slidesBanner = [
    { img: "/assets/image/img 1.jpg" },
    { img: "/assets/image/img 2.jpg" },
    { img: "/assets/image/img 3.jpg" }
  ];

  slides;

  loading = {
    screen: false,
    login: false,
    register: false
  }

  email: string = '';
  password: string = '';
  password2: string = '';
  msg_error: string = '';
  token;

  config = {
    facebook: '',
    instagram: '',
    youtube: '',
    linkedin: ''
  };

  ngOnInit(): void {
    this.token = this.storageService.getToken()
    if (this.token != null) {
      this.router.navigate(['/cases'])
    }
    else {
      this.getFeedbacks()
      this.getConstants()
    }
  }

  afterChange(e) {
    // console.log('afterChange');
  }

  beforeChange(e) {
    // console.log('beforeChange');
  }

  getConstants() {
    this.constantsService.getConstants().subscribe(data => {
      this.config = data.config;
    }, error => {
      console.log(error)
    })
  }

  getFeedbacks() {
    this.constantsService.getFeedbacks().subscribe(data => {
      this.slides = data.feedbacks
    }, error => {
      console.log(error)
    })
  }

  login() {
    if (this.email == null || this.email == '') {
      this.msg_error = 'Por favor, digite o email';
      return
    }
    else if (this.password == null || this.password == '') {
      this.msg_error = 'Por favor, digite a senha';
      return
    }
    else if ((this.password != null || this.password != '') && (this.email != null || this.email != '')) {
      this.loading.login = true;
      this.msg_error = '';

      this.authService.auth(this.email, this.password).subscribe(data => {
        this.loading.login = false;
        this.storageService.login(data?.token)
        this.router.navigate(['/cases']);
        this.loading.register = false;
      },
        error => {
          this.loading.login = false;
          this.msg_error = "Email ou senha incorretos"
        })
    }
  }

  register() {
    if (this.email == null || this.email == '') {
      this.msg_error = 'Por favor, digite o email';
      return
    }
    else if (this.password == null || this.password == '') {
      this.msg_error = 'Por favor, digite a senha';
      return
    }
    else if (this.password2 == null || this.password2 == '') {
      this.msg_error = 'Por favor, confirme sua senha';
      return
    }
    else if (this.password != this.password2) {
      this.msg_error = 'Senha incorreta, por favor, confira as senhas e tente novamente';
      return
    }
    else {
      this.loading.register = true;
      this.msg_error = "";

      this.authService.register(this.email, this.password).subscribe(data => {
        this.login()
      },
        error => {
          if (error.error.email != null) {
            this.msg_error = error.error.email
          }
          else {
            this.msg_error = "Email ou senha incorretos"
          }
          this.loading.register = false;
        })
    }
  }

  slideConfigBanner = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": true,
    "arrows": false,
    "infinite": false,
    "autoplay": true,
    "swipeToSlide": true,
    "autoplaySpeed": 3000,
  }

  slideConfigUser = {
    "slidesToShow": 5,
    "slidesToScroll": 1,
    "dots": false,
    "arrows": true,
    "infinite": false,
    "autoplay": false,
    "swipeToSlide": true,
    "autoplaySpeed": 3000,
    "responsive": [
      {
        "breakpoint": 900,
        "settings": {
          "slidesToShow": 4,
          "autoplaySpeed": 3000,
        }
      },
      {
        "breakpoint": 800,
        "settings": {
          "slidesToShow": 3,
          "autoplaySpeed": 3000,
        }
      },
      {
        "breakpoint": 570,
        "settings": {
          "slidesToShow": 2,
          "autoplaySpeed": 3000,
        }
      },
      {
        "breakpoint": 470,
        "settings": {
          "slidesToShow": 1,
          "autoplaySpeed": 3000,
        }
      }
    ]
  };

}
