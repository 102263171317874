<div class="screen">
  <div class="case-details">

    <div
      style="position: absolute; width: 90%; max-width: 1500px; margin: -50px auto 0; display: flex; justify-content: right;">
      <button mat-button class="button-orange" (click)="openPopup()">
        Próximo caso
      </button>
    </div>
    <div class="coll1">
      <div class="div-row">
        <div style="width: auto; display: flex; align-items: center;">
          <div class="img-user">
            <img src="../../assets/image/setup-orange.jpg">
          </div>
          <div
            style="display: flex; flex-direction: column; font-size: calc(10px + 0.4vw); line-height: 1.5; margin-left: 15px;">
            <div style="font-weight: 600;">
              Paulo Henrique
            </div>
            1,78m e 70kg <br>
            76 anos <br>
            Sexo: Masculino <br>
          </div>
          <div style="width: auto; display: flex; margin: 0 0 auto auto; flex-direction: column;">
            <img src="../../assets/image/back.png" style="height: 40px; cursor: pointer; margin: 0 0 3vw auto;">
            <div style="font-size: calc(13px + 0.3vw); color: white;">
              <span> <img src="../../../assets/image/time.png" width="25px" style="margin: 0 10px 5px 0;"> </span> 3:45
            </div>
          </div>
        </div>
        <div style="margin-top: 12px;">
          <div style="color: #ef6442; font-size: 17px; margin-bottom: 10px;"> Queixa do paciente: </div>
          <ng-scrollbar class="scrollbar-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
            enim ad minim veniam,
          </ng-scrollbar>
        </div>
      </div>

      <div class="div-row">
        <ng-scrollbar class="my-scrollbar">

          <div style="width: 100%; display: flex; justify-content: space-evenly; flex-wrap: wrap;">
            <div *ngFor="let obj of teste; let i = index" style="width: 100%; display: flex; flex-direction: column; ">

              <div
                style="width: 92%; display: flex; align-items: center; flex-direction: row; justify-content: space-between;">
                <div style="color: #ef6442; font-size: 18px;">
                  Lorem Ipsum
                </div>
                <button mat-button class="button">
                  Correto
                </button>
              </div>
              <div style="width: 92%; font-size: calc(10px + 0.3vw); margin-bottom: 10px;">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                ullamco
              </div>

            </div>
          </div>

        </ng-scrollbar>
      </div>
    </div>

    <div class="div-ranking">
      <div class="coll-score div-border">
        <div style="color: #ef6442; font-size: calc(20px + 0.3vw); font-weight: 600; margin: 0 0 50px;">
          Ranking
        </div>
        <div class="circle-borde">
          13°
        </div>
      </div>

      <div class="coll-score div-border">
        <div style="color: #ef6442; font-size: calc(20px + 0.3vw); font-weight: 600; margin: 0 0 50px;">
          Meu score
        </div>
        <div class="circle-borde">
          <div> 150 </div>
          <div style="font-size: 17px; margin-top: 7px; line-height: 1;"> pontos </div>
        </div>
      </div>
    </div>

    <div class="classification">
      <div class="coll-score div-border">
        <div style="color: #ef6442; font-size: calc(20px + 0.3vw); font-weight: 600; margin: 0 auto 10px 50px;">
          Classificação
        </div>
        <div
          style="width: 90%; margin: 0 auto; display: flex; flex-direction: row; align-items: center; justify-content: center; margin: 20px 0;">
          <div style="width: 45%; margin: 0 auto;">
            <mat-form-field appearance="fill" style="width: 100%;">
              <mat-select>
                <mat-option *ngFor="let obj of TextExem" [value]="obj">
                  {{obj}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div style="width: 45%; margin: 0 auto;">
            <mat-form-field appearance="fill" style="width: 100%;">
              <mat-select>
                <mat-option *ngFor="let obj of TextExem" [value]="obj">
                  {{obj}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <ng-scrollbar class="scrollbar-classification">
          <div *ngFor="let obj of teste; let i = index" class="div-users-ranking">

            <div
              style="width: 95%; display: flex; justify-content: space-between; align-items: center; margin: 10px 0; padding: 0 20px 0 5px;">
              <div class="div-img">
                <img [src]="obj.img" width="80px">
              </div>
              <div style="width: 60%; margin-left: 10px;">
                <div style="color: #ef6442; font-size: 16px; font-weight: 600; margin-bottom: 5px;"> {{ obj.ranking }}º
                  Colocado </div>
                <div class="break-name" style="font-size: 14px; max-height: 40px;"> {{ obj.name }} </div>

              </div>
              <div style="font-size: 18px; font-weight: 600;"> {{ obj.score }} </div>
            </div>

          </div>
        </ng-scrollbar>

      </div>

    </div>
  </div>
</div>


<!-- <div style="width: 100%; background-color: #0a1e2d;">
    <div class="footer">
        <div class="coll-externo">
            <div class="coll-interno">
                <a href=""> Busca </a>
                <a href=""> Casos </a>
                <a href=""> Mande seu caso clínico </a>
            </div>
            <div class="coll-interno">
                <a href=""> Perfil </a>
                <a href=""> Casos feitos</a>
            </div>
            <div class="coll-interno">
                <a href=""> Dados cadastrais </a>
                <a href=""> Configurações </a>
            </div>
        </div>
        <div class="coll-social">
            <img src="../../assets/icons/facebook.png" class="img-social">
            <img src="../../assets/icons/insta.png" class="img-social">
            <img src="../../assets/icons/linkedin.png" class="img-social">
        </div>
    </div>
</div> -->
