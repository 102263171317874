<div style="position: relative;">

  <div class="header">
    <div class="picture-back">
      <ngx-slick-carousel [config]="slideConfigBanner" style="width: 100%; height: 100%;"
        (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
        <div ngxSlickItem *ngFor="let slide of slidesBanner" class="slide">
          <img src="{{ slide.img }}" class="banner-img">
        </div>
      </ngx-slick-carousel>

      <!--img src="../../assets/image/radiology.jpg"-->
      <div class="black"></div>
    </div>

    <div class="div-row">
      <div class="div-slide-top">
        <div class="text-title">
          Estude casos clínicos <br>
          em nutrologia
        </div>
        <button mat-button class="button"> Vamos lá! </button>
      </div>

      <div *ngIf="page == 1" class="div-app">
        <div class="div-login">
          <div style="color: black; font-size: calc(25px + 0.5vw); font-weight: 600; padding: 30px 0;">
            Login
          </div>

          <div class="div-input">
            <input type="text" placeholder="email" [(ngModel)]="email">
          </div>

          <div class="div-input">
            <input type="password" placeholder="senha" [(ngModel)]="password" (keyup.enter)="login()">
          </div>
          <div style="height: 20px; color: red; text-align: center; font-size: 12px; margin: 14% auto 0;">
            {{ msg_error }}
          </div>
          <button mat-button class="button btn-login" (click)="login()"> Entrar <span *ngIf="loading.login"
              class="spinner-border spinner-border-sm" style="margin: 0 auto;" role="status" aria-hidden="true"></span>
          </button>
        </div>
      </div>

      <div *ngIf="page == 2" class="div-app">
        <div class="div-login">
          <div style="color: black; font-size: calc(22px + 0.3vw); font-weight: 600; padding: 30px 0;">
            Cadastre-se
          </div>

          <div class="div-input">
            <input mat-input placeholder="email" [(ngModel)]="email">
          </div>

          <div class="div-input">
            <input mat-input type="password" placeholder="senha" [(ngModel)]="password">
          </div>

          <div class="div-input">
            <input mat-input type="password" placeholder="confirmação de senha" [(ngModel)]="password2"
              (keyup.enter)="register()">
          </div>

          <div style="height: 20px; color: red; text-align: center; font-size: 12px; margin: 14% auto 0;">
            {{ msg_error }}
          </div>

          <button mat-button class="button btn-login" (click)="register()"> Cadastrar <span *ngIf="loading.register"
              class="spinner-border spinner-border-sm" style="margin: 0 auto;" role="status" aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="templates">
    <div class="title"> Como funciona? </div>

    <div style="height: 35px;"></div>

    <div class="template">
      <div *ngFor="let icon of teste; let i = index" class="div-template">

        <div
          style="width: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column; max-width: 250px;">
          <img class="circle-img" src="{{ icon.img }}">
          <div class="text-template">
            {{icon.text}}
          </div>
          <div *ngIf="i == 0" style="color: #ef6443; margin-top: 15px; font-size: 13px; text-align: center;">
            Ainda não é aluno? <br>
            <u> <a href="http://nutrologyacademy.com" style="color: #ef6443; outline: none;" target="_blank">
                Conheça nossos cursos
              </a> </u>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="screen">
    <div class="div-text">
      <div class="title"> Quem somos </div>
      <div style="text-align: left; margin: 15px 0; font-size: 16px;">
        A Nutrology Academy é uma plataforma de ensino on line que tem como objetivo promover
        o crescimento dos conhecimentos em Nutrologia. Sempre partindo da ciência e do dia a
        dia prático do consultório para assim contribuir com uma sociedade mais saudável.
      </div>
      <a href="http://nutrologyacademy.com" style="color: #ef6443; outline: none;" target="_blank">
        <button mat-button class="button button-orange" style="margin: 20px 0 0;"> Saiba mais </button>
      </a>
    </div>
    <div class="div-img">
      <img src="../../assets/image/radiology.jpg">
    </div>
  </div>

  <!--<div class="feedback">
        <div class="title"> Feedback dos usuários</div>

        <ngx-slick-carousel [config]="slideConfigUser" style="max-width: 1400px;"
        (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
            <div ngxSlickItem *ngFor="let slide of slides" class="slide" style="margin: 0 10px;">
                <div class="img-perfil">
                    <img src="{{ slide?.profile_image }}" alt="" width="100">
                </div>

                <div style="color: #ef6443; text-align: center; font-size: 22px; font-weight: 600; margin: 10px 0;">
                    {{ slide?.name }}
                </div>

                <div style="width: 90%; text-align: center; font-size: 14px;">
                    {{ slide?.text }}
                </div>
            </div>
        </ngx-slick-carousel>
    </div>-->
  <div class="feedback" style="padding: 1px;"></div>

  <div class="footer">
    <div class="coll-externo">
      <div class="coll-interno">
        <!--<a href=""> Busca </a>-->
        <a href=""> Casos </a>
        <a href=""> Mande seu caso clínico </a>
      </div>
      <div class="coll-interno">
        <a href=""> Perfil </a>
        <a href=""> Casos feitos</a>
      </div>
      <div class="coll-interno">
        <a href=""> Dados cadastrais </a>
        <a href=""> Configurações </a>
      </div>
    </div>
    <div class="coll-social">
      <a [href]="config.facebook" target="_blank" *ngIf="config.facebook != '' && config.facebook != null ">
        <img src="../../assets/icons/facebook_preto.png" class="img-social">
      </a>

      <a [href]="config.instagram" target="_blank" *ngIf="config.instagram != '' && config.instagram != null">
        <img src="../../assets/icons/insta_preto.png" class="img-social">
      </a>

      <a [href]="config.youtube" target="_blank" *ngIf="config.youtube != '' && config.youtube != null">
        <img src="../../assets/icons/youtube.png" class="img-social">
      </a>

      <a [href]="config.linkedin" target="_blank" *ngIf="config.linkedin != '' && config.linkedin != null">
        <img src="../../assets/icons/linkedin_preto.png" class="img-social">
      </a>
    </div>
  </div>

</div>
