<div class="screen" *ngIf="page == 1">
  <div
    style="width: 100%; height: 50px; display: flex; align-items: center;  justify-content: space-between; color: #ef6442; font-size: calc(22px + 0.4vw); margin: 0; font-weight: 600; margin-bottom: 20px;">
    Solucionar caso
    <!-- <div style="font-size: 17px; color: white;">
            <span> <img src="../../../assets/image/time.png" width="25px" style="margin: 0 10px 5px 0;"> </span> 3:45
        </div> -->
  </div>

  <div style="width: 100%; display: flex; justify-content: space-between; align-items: center;"
    *ngIf="case?.questions != null">
    <div style="color: white; font-size: 22px; margin: 20px 0 40px">
      {{ case?.questions[question]?.text }}
    </div>
    <div style="font-size: 18px; margin: 20px 0 40px auto;">
      {{ question+1 }}/{{ case?.questions.length }}
    </div>
  </div>

  <div *ngIf="case?.questions != null"
    style="width: 100%; padding: 0 10px 0 0; margin: 0 0 auto; font-size: 17px; line-height: 1.3; display: flex; flex-direction: column;">
    <mat-radio-group aria-label="Select an option" [(ngModel)]="answeSelect"
      style="display: flex; flex-direction: column;">
      <mat-radio-button *ngFor="let obj of case.questions[question]?.answers; let i = index" [value]="obj.id">
        <div style="display: flex; flex-direction: column; justify-content: flex-start;"> {{ obj.title }} </div>
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div *ngIf="case?.questions != null"
    style="width: 100%; display: flex; justify-content: flex-end; align-items: flex-end;">
    <div style="width: 100%; text-align: left;">
      {{ messageErro }}
    </div>

    <button mat-button class="button" *ngIf="case.questions.length-1 > question" (click)="nextQuestion()">
      Proximo
    </button>

    <button *ngIf="case.questions.length-1 == question" mat-button class="button" (click)="sendCase()">
      Enviar
    </button>
  </div>

  <div *ngIf="case?.questions == null">
    Nenhuma questão encontrada.
  </div>
</div>

<div class="screen" *ngIf="page == 3">
  <div
    style="width: 100%; height: 50px; display: flex; align-items: center;  justify-content: space-between; color: #ef6442; font-size: calc(22px + 0.4vw); margin: 0; font-weight: 600; margin-bottom: 20px;">
    Resposta do caso
    <!-- <div style="font-size: 17px; color: white;">
            <span> <img src="../../../assets/image/time.png" width="25px" style="margin: 0 10px 5px 0;"> </span> 3:45
        </div> -->
  </div>

  <div style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
    <div *ngIf="case?.questions != null" style="color: white; font-size: 22px; margin: 20px 0 40px">
      {{ case?.questions[page_answers]?.text }}
    </div>
    <div style="font-size: 18px; margin: 20px 0 40px auto;">
      {{ page_answers+1 }}/{{ case.questions.length }}
    </div>
  </div>

  <div
    style="width: 100%; padding: 0 10px 0 0; margin: auto 0; font-size: 17px; line-height: 1.3; display: flex; flex-direction: column;">

    <mat-radio-group aria-label="Select an option"
      *ngFor="let obj of case.questions[page_answers]?.answers; let i = index" [(ngModel)]="my_answers[page_answers]">

      <mat-radio-button [value]="obj.id" disabled="true">
        <div [ngStyle]="{'font-weight': obj.correct? '500' : ''}"
          style="display: flex; flex-wrap: wrap; justify-content: flex-start; align-items: flex-start; color: white;">
          {{ obj.title }} <span style="font-size: 20px; font-weight: 600; line-height: 1; margin-left: 6px;"> {{
            obj.correct? '- Resposta certa' : '' }} </span>
        </div>
      </mat-radio-button>
    </mat-radio-group>

  </div>

  <div style="width: 100%; display: flex; justify-content: space-between; align-items: flex-end;">
    <div>
      <button mat-button class="button" (click)="backAnswer()" *ngIf="page_answers != 0" style="margin-right: 10px;">
        Voltar
      </button>

      <button mat-button class="button" (click)="nextAnswer()" *ngIf="page_answers != case.questions.length-1">
        Proximo
      </button>
    </div>

    <button mat-button class="button" routerLink="/made-cases">
      Meus Casos
    </button>
  </div>
</div>

<div class="screen" *ngIf="page == 2">
  <div
    style="width: 100%; height: 50px; display: flex; align-items: center;  justify-content: space-between; color: #ef6442; font-size: calc(22px + 0.4vw); margin: 0; font-weight: 600; margin-bottom: 35px;">
    Recordatório alimentar
    <div style="font-size: 17px; color: white;">
      <span> <img src="../../../assets/image/time.png" width="25px" style="margin: 0 10px 5px 0;"> </span> 3:45
    </div>
  </div>
  <div style="width: 100%; overflow-x: auto; padding: 0 10px 0 0; margin: auto 0; font-size: 17px; line-height: 1.3;">

    <div style="width: 100%; min-width: 600px; display: flex; justify-content: space-between;">
      <div class="div-input div-60">
        Café da manhâ
        <input type="text" [(ngModel)]="text1">
      </div>

      <div class="div-input">
        Gord
        <input type="text" [(ngModel)]="text2">
      </div>

      <div class="div-input">
        Carbs
        <input type="text" [(ngModel)]="text2">
      </div>

      <div class="div-input">
        Prot
        <input type="text" [(ngModel)]="text2">
      </div>

      <div class="div-input">
        Cals
        <input type="text" [(ngModel)]="text2">
      </div>
    </div>
    <div style="height: 25px; cursor: pointer; display: flex; justify-content: flex-start; margin-bottom: 23px;">
      <img src="../../../assets/icons/adicionar.png" width="25" height="25">
      <div style="margin-left: 10px; color: #ef6442; line-height: 1.5;">
        adicionar
      </div>
    </div>

    <div style="width: 100%; min-width: 600px; display: flex; justify-content: space-between;">
      <div class="div-input div-60">
        Almoço
        <input type="text" [(ngModel)]="text1">
      </div>

      <div class="div-input">
        Gord
        <input type="text" [(ngModel)]="text2">
      </div>

      <div class="div-input">
        Carbs
        <input type="text" [(ngModel)]="text2">
      </div>

      <div class="div-input">
        Prot
        <input type="text" [(ngModel)]="text2">
      </div>

      <div class="div-input">
        Cals
        <input type="text" [(ngModel)]="text2">
      </div>
    </div>
    <div style="height: 25px; cursor: pointer; display: flex; justify-content: flex-start; margin-bottom: 23px;">
      <img src="../../../assets/icons/adicionar.png" width="25" height="25">
      <div style="margin-left: 10px; color: #ef6442; line-height: 1.5;">
        adicionar
      </div>
    </div>

    <div style="width: 100%; min-width: 600px; display: flex; justify-content: space-between;">
      <div class="div-input div-60">
        Jantar
        <input type="text" [(ngModel)]="text1">
      </div>

      <div class="div-input">
        Gord
        <input type="text" [(ngModel)]="text2">
      </div>

      <div class="div-input">
        Carbs
        <input type="text" [(ngModel)]="text2">
      </div>

      <div class="div-input">
        Prot
        <input type="text" [(ngModel)]="text2">
      </div>

      <div class="div-input">
        Cals
        <input type="text" [(ngModel)]="text2">
      </div>
    </div>
    <div style="height: 25px; cursor: pointer; display: flex; justify-content: flex-start; margin-bottom: 23px;">
      <img src="../../../assets/icons/adicionar.png" width="25" height="25">
      <div style="margin-left: 10px; color: #ef6442; line-height: 1.5;">
        adicionar
      </div>
    </div>

  </div>
  <div class="div-reparation">
    <div>
      <div
        style="width: 100%; display: flex; justify-content: space-between; color: #ef6442; font-size: 18px; margin: 0; font-weight: 200; margin: 0;">
        Repartição das calorias:
      </div>
      <div style="display: flex; align-items: center;">
        <div style="width: 100%; display: flex; flex-wrap: wrap; flex-direction: row; align-items: center;">
          <div style="display: flex; align-items: center; margin: 0 10px 0 0;">
            <div class="div-circle" style="background-color: #EFAA42;"></div>
            <div> Carboidratos (58%) </div>
          </div>
          <div style="display: flex; align-items: center; margin: 0 10px 0 0;">
            <div class="div-circle" style="background-color: #602439;"></div>
            <div> Gorduras (0%) </div>
          </div>
          <div style="display: flex; align-items: center; margin: 0 10px 0 0;">
            <div class="div-circle" style="background-color: #00B4C1;"></div>
            <div> Proteínas (42%) </div>
          </div>
        </div>
        <div style="width: 100px;">
          <canvas baseChart #chart1 [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
            [legend]="barChartLegend" [chartType]="barChartType">
          </canvas>
        </div>
      </div>
    </div>
    <button mat-button class="button" (click)="openVideo()">
      Enviar
    </button>
  </div>
</div>
