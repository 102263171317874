import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medals',
  templateUrl: './medals.component.html',
  styleUrls: ['./medals.component.css']
})

export class MedalsComponent implements OnInit {

  constructor() { }

  medals;

  medalsAux = [
    { img: '../../assets/icons/medalha_inativa.png', name: 'Nutrology Academy', year: '-' },
    { img: '../../assets/icons/medalha_inativa.png', name: 'Nutrology Academy', year: '-' },
    { img: '../../assets/icons/medalha_inativa.png', name: 'Nutrology Academy', year: '-' },
    { img: '../../assets/icons/medalha_inativa.png', name: 'Nutrology Academy', year: '-' },
    { img: '../../assets/icons/medalha_inativa.png', name: 'Nutrology Academy', year: '-' },
    { img: '../../assets/icons/medalha_inativa.png', name: 'Nutrology Academy', year: '-' },
    { img: '../../assets/icons/medalha_inativa.png', name: 'Nutrology Academy', year: '-' },
    { img: '../../assets/icons/medalha_inativa.png', name: 'Nutrology Academy', year: '-' },
    { img: '../../assets/icons/medalha_inativa.png', name: 'Nutrology Academy', year: '-' },
  ]

  ngOnInit(): void {
    this.MedalsJoin()
  }

  MedalsJoin() {
    for (let index = 0; index < this.medals.length; index++) {
      this.medalsAux[index].img = this.medals[index].img;
      this.medalsAux[index].name = this.medals[index].name;
      this.medalsAux[index].year = this.medals[index].year;
    }
  }

}
