<div class="screen">
  <div class="title">
    Configurações
  </div>

  <div class="icon-close" (click)="close()">
    <img src="../../../assets/image/icon-close.png" width="17">
  </div>

  <div class="div-toggle">
    <div> Receber emails comercias </div>
    <mat-slide-toggle [(ngModel)]="commercial" (click)="teste('0')"></mat-slide-toggle>
  </div>

  <div class="div-toggle">
    <div> Aparecer no ranking geral </div>
    <mat-slide-toggle [(ngModel)]="ranking" (click)="teste('1')"></mat-slide-toggle>
  </div>

  <div class="div-toggle">
    <div> Receber emails de lembretes </div>
    <mat-slide-toggle [(ngModel)]="notification" (click)="teste('2')"></mat-slide-toggle>
  </div>

  <div style="height: 15px;"></div>

  <a [href]="config.terms_of_use" target="_blank" *ngIf="config.terms_of_use != '' && config.terms_of_use != null "
    style="text-decoration: underline; font-size: 16px; color: #EF6442; cursor: pointer;">
    Termos de uso
  </a>

  <div style="height: 20px;"></div>

  <a [href]="config.privacy_policies" target="_blank"
    *ngIf="config.privacy_policies != '' && config.privacy_policies != null "
    style="text-decoration: underline; font-size: 16px; color: #EF6442; cursor: pointer;">
    Políticas de privacidade
  </a>
</div>
