import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})

export class SuggestionCaseService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) { }

  coinsSubject = new Subject<String>();

  constants;
  config;

  getSuggestionCase(): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('Authorization', `token ${this.storageService.getToken()}`)
    };

    return this.http.get<any>(AppConfig.path + "core/suggestion-case/", options)
  }

  postSuggestionCase(list): Observable<any> {

    let body: any = JSON.stringify({
      complain: list.complain,
      food_recall: list.food_recall,
      biochemistry: list.biochemistry,
      body_composition: list.body_composition,
      exams: list.exams,
      medicine: list.medicine,
      exercise: list.exercise,
      sleep: list.sleep,
      images: list.images
    })

    var headers = new HttpHeaders({ 'Content-Type': 'application/json' })
      .set('Authorization', `token ${this.storageService.getToken()}`)

    return this.http.post<any>(AppConfig.path + `core/suggestion-case/`, body, { headers })
  }

  patchSuggestionCase(list): Observable<any> {

    let body: any = JSON.stringify({
      complain: list.complain,
      food_recall: list.food_recall,
      biochemistry: list.biochemistry,
      body_composition: list.body_composition,
      exams: list.exams,
      medicine: list.medicine,
      exercise: list.exercise,
      sleep: list.sleep,
      // images: images
    })

    var headers = new HttpHeaders({ 'Content-Type': 'application/json' })
      .set('Authorization', `token ${this.storageService.getToken()}`)

    return this.http.patch<any>(AppConfig.path + `core/suggestion-case/${list.id}/`, body, { headers })
  }

}
