<div style="width: 100%; height: auto; min-height: calc(100vh - 250px); background-color: #13293a;">
  <div class="loading" *ngIf="loading">
    <div>
      <mat-spinner diameter="50" strokeWidth="7"></mat-spinner>
    </div>
  </div>

  <div class="screen" *ngIf="!loading">

    <div class="coll1 div-border">
      <div class="div-img">
        <img [src]="pacient?.image == null? '../../assets/image/setup-orange.jpg' : pacient.image ">
      </div>
      <div class="div-text break-line" style="margin: 30px 0 5px;">
        {{ pacient?.name }}
      </div>
      <div class="div-text">
        {{ pacient?.age }}
      </div>
      <div class="div-text">
        {{ pacient?.gender }}
      </div>
      <div class="div-text">
        {{ pacient?.weight }} kg
      </div>
      <div class="div-text">
        {{ pacient?.height }} m
      </div>
    </div>

    <div class="coll2 div-border">
      <div class="title">
        {{ case?.title }}
      </div>
      <div style="color: #ef6442; font-size: calc(20px + 0.3vw);">
        Queixa do paciente:
      </div>
      <div style="max-width: 550px; margin: 10px 0;">
        {{ case?.description }}
      </div>
      <!-- <div style="margin-top: 50px; font-size: 16px; color: #ef6442;">
                Quem já resolveu esse caso:
            </div> -->
      <!-- <div style="width: 100%; margin: 10px 0; display: flex; align-items: center; justify-content: left;">
                <div *ngFor="let obj of imgtext; let i = index">
                    <div class="img-users" [ngStyle] = "{ 'margin-left': i!=0? '-10px' : '0' }">
                        <img [src]="obj.img">
                    </div>
                </div>
                <div style="color: #ef6442; font-size: 16px; margin-left: 20px;">
                    +100
                </div>
            </div> -->
      <button mat-button class="button" routerLink="/case/{{ case_id }}/case-started">
        Iniciar
      </button>
    </div>
  </div>
</div>

<!-- <div style="width: 100%; background-color: #0a1e2d;">
    <div class="footer">
        <div class="coll-externo">
            <div class="coll-interno">
                <a href=""> Busca </a>
                <a href=""> Casos </a>
                <a href=""> Mande seu caso clínico </a>
            </div>
            <div class="coll-interno">
                <a href=""> Perfil </a>
                <a href=""> Casos feitos</a>
            </div>
            <div class="coll-interno">
                <a href=""> Dados cadastrais </a>
                <a href=""> Configurações </a>
            </div>
        </div>
        <div class="coll-social">
            <img src="../../assets/icons/facebook.png" class="img-social">
            <img src="../../assets/icons/insta.png" class="img-social">
            <img src="../../assets/icons/linkedin.png" class="img-social">
        </div>
    </div>
</div> -->
