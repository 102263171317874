<div class="screen">

  <div class="title">
    Medalhas
  </div>

  <div style="width: 100%; display: flex; flex-wrap: wrap;">
    <div *ngFor="let medal of medalsAux" class="div-medal">
      <img [src]="medal.img" class="medal-img">
      <div class="medal-name" [ngStyle]="{'color': medal.img == '../../assets/icons/medalha2.png'? '#ef6443' :
                                     ( medal.img == '../../assets/icons/medalha1.png'? '#602439' : '#C2C2C2') }">
        {{ medal.name }}
      </div>
      <div style="font-size: 15px;">
        {{ medal.year }}
      </div>
    </div>
  </div>

</div>
