<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark fixed-top scrolling-navbar"
  style="overflow-x: hidden !important; font-size: 20px !important; overflow: hidden; z-index: 99999;">

  <mdb-navbar-brand>
    <img src="../../assets/icons/logo.png" width="150" class="logo btn_router" routerLink="/cases">
  </mdb-navbar-brand>

  <links>

    <ul *ngIf="accessToken == true" class="navbar-nav ml-auto" id="normal-bar">
      <!-- <li class="nav-item">
                <a class="nav-link">
                    Buscar
                </a>
            </li> -->

      <li class="nav-item">
        <a class="nav-link" routerLink="/cases" routerLinkActive="active">
          Casos
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLink="/send-case" routerLinkActive="active">
          Mande o seu caso clínico
        </a>
      </li>

      <li class="nav-item div-login">
        <a class="nav-link" routerLink="/user" routerLinkActive="active">
          Perfil
        </a>
      </li>
    </ul>

    <ul *ngIf="accessToken == false" class="navbar-nav ml-auto" id="normal-bar">
      <li class="nav-item" style="display: none;">
        <a class="nav-link">
          Nutrology Academy
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link">
          Quem somos
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link">
          Caso degustação
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLink="/register">
          Cadastre-se
        </a>
      </li>

      <li class="nav-item div-login">
        <a class="nav-link" mdbWavesEffect routerLink="/login">
          <button mat-raised-button class="button">
            Login
          </button>
        </a>
      </li>
    </ul>

  </links>

</mdb-navbar>


<div style="position: relative; z-index: 10;">
  <router-outlet></router-outlet>
</div>


<div style="width: 100%; background-color: #0a1e2d;" *ngIf="url != '/' && url != '/login' && url != '/register' ">
  <div class="footer">

    <div class="coll-externo">
      <div class="coll-interno">
        <!-- <a href=""> Busca </a> -->
        <a href="/cases"> Casos </a>
        <a href="/send-case"> Mande seu caso clínico </a>
      </div>

      <div class="coll-interno">
        <a href="/user"> Perfil </a>
        <a href="/made-cases"> Casos feitos</a>
      </div>

      <div class="coll-interno">
        <a href=""> Dados cadastrais </a>
        <a href=""> Configurações </a>
      </div>
    </div>

    <div class="coll-social">
      <a [href]="config.facebook" target="_blank" *ngIf="config.facebook != '' && config.facebook != null ">
        <img src="../../assets/icons/facebook.png" class="img-social">
      </a>

      <a [href]="config.instagram" target="_blank" *ngIf="config.instagram != '' && config.instagram != null ">
        <img src="../../assets/icons/insta.png" class="img-social">
      </a>

      <a [href]="config.youtube" target="_blank" *ngIf="config.youtube != '' && config.youtube != null ">
        <img src="../../assets/icons/youtube_1.png" class="img-social">
      </a>

      <a [href]="config.linkedin" target="_blank" *ngIf="config.linkedin != '' && config.linkedin != null ">
        <img src="../../assets/icons/linkedin.png" class="img-social">
      </a>
    </div>

  </div>
</div>
