<div class="screen">
  <div class="loading" *ngIf="loading">
    <div>
      <mat-spinner diameter="30" strokeWidth="4"></mat-spinner>
    </div>
  </div>

  <div class="case-details" *ngIf="!loading">

    <div class="coll1">
      <div class="div-row">
        <div style="display: flex; justify-content: space-between; align-items: flex-start; flex-wrap: wrap;">
          <div style="color: #ef6442; font-size: calc(20px + 0.4vw); margin: 0; font-weight: 600; margin-bottom: 20px;">
            {{ case?.title }}
            <!-- <img src="../../assets/icons/adicionar.png" class="icon-back"> -->
          </div>
          <button mat-button class="btn-orange" routerLink="/case/{{case_id}}/case-started"> Refazer </button>
        </div>
        <div style="font-style: 15px;">
          {{ case?.description }}
        </div>
        <br>
        <div style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
          <div style="font-size: calc(18px + 0.3vw); margin: 10px 0 0;">
            {{ case_details?.user_questions[question]?.text }}
          </div>
          <div style="width: 80px; display: flex; justify-content: space-between; align-items: center;">
            <img src="../../assets/icons/chevron_left.svg" class="btn-arrow" (click)="backCase()"
              [ngClass]="{'disabled': question == 0}">

            <img src="../../assets/icons/chevron_right.svg" class="btn-arrow" (click)="nextCase()"
              [ngClass]="{'disabled': (case_details?.user_questions.length-1) == question}">
          </div>
        </div>
        <div class="text-description custom-scrollbar">

          <div *ngFor="let obj of case_details.user_questions[question].answers">
            <div style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
              <!-- <div class="border-div" style="width: 20%; font-weight: 600;"> Lorem Ipsum </div> -->
              <div class="border-div answer">
                <div class="break-line"
                  [ngClass]="{'correct': case_details?.user_questions[question]?.last_user_answers?.awnswer_case == obj?.id}">
                  {{ obj.title }}
                </div>
              </div>
              <div class="div-status div-answer" style="background: #72D4A3;" *ngIf="obj.correct">
                Correto
              </div>

              <div class="div-status div-answer" style="background: #DC4E4E;" *ngIf="!obj.correct">
                Incorreto
              </div>
            </div>
          </div>

          <!-- <div style="width: 100%; display: flex; justify-content: space-between;">
                        <div style="width: 20%; font-weight: 600; padding: 10px;"> Lorem Ipsum </div>
                        <div style="width: 58%; margin: 0 1%">
                            <div class="break-line" style="padding: 4px 10px;">
                                A - Lorem ipsum dolor sit amet, consectetur
                            </div>
                        </div>
                        <div class="div-status" style="width: 20%; background: #72D4A3;">
                            Correto
                        </div>
                    </div>

                    <div style="width: 100%; display: flex; justify-content: space-between;">
                        <div style="width: 20%; font-weight: 600; padding: 10px;"> Lorem Ipsum </div>
                        <div style="width: 58%; margin: 0 1%;">
                            <div class="break-line" style="padding: 4px 10px;">
                                B - Lorem ipsum dolor sit amet, consectetur
                            </div>
                        </div>
                        <div class="div-status" style="width: 20%; background: #DC4E4E;">
                            Incorreto
                         </div>
                    </div> -->

        </div>
      </div>

      <div class="div-row">
        <div
          style="width: 100%; display: flex; justify-content: space-between; align-items: flex-start; flex-wrap: wrap; margin-bottom: 20px;">
          <div class="ranking-filtro">
            <div style="text-align: center; color: #ef6442; font-size: calc(20px + 0.4vw); font-weight: 600;">
              Ranking
            </div>
            <div class="div-select">
              <div style="margin-left: 15px;">
                <mat-form-field appearance="fill">
                  <mat-label style="color: white; opacity: 0.8;">{{ state == null ? 'Estado' : ''}}</mat-label>
                  <mat-select [(ngModel)]="state">
                    <mat-option *ngFor="let obj of list_state" [value]="obj.name" (click)="selectState(obj)">
                      {{obj.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div style="margin-left: 15px;">
                <mat-form-field appearance="fill">
                  <mat-label style="color: white; opacity: 0.8;">{{ city == null ? 'Cidade' : ''}}</mat-label>
                  <mat-select [(ngModel)]="city">
                    <mat-option *ngFor="let obj of list_cities" [value]="obj" (click)="filter_case()">
                      {{obj}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div style="color: #ef6442;">
            {{qtd_user -case_details.ranking_list.length == 0 ? "" : '+'}} {{qtd_user -case_details.ranking_list.length
            == 0 ? "" : qtd_user - case_details.ranking_list.length}}
          </div>
        </div>

        <div>
          <ng-scrollbar class="my-scrollbar">

            <div style="width: 100%; display: flex; justify-content: space-evenly; flex-wrap: wrap;">
              <div *ngFor="let obj of case_details.ranking_list; let i = index" class="div-users-ranking">

                <div style="width: 100%; display: flex; justify-content: center; margin: 30px 0;">
                  <div class="div-img">
                    <img
                      [src]="obj.user_obj.profile_image  != null ? obj.user_obj.profile_image: '../../../assets/image/bege.png'"
                      width="80px">
                  </div>
                  <div style="width: calc(100% - 7.5vw); margin-left: 10px;">
                    <div style="color: #ef6442; font-size: 18px; font-weight: 600; margin-bottom: 10px;"> {{
                      obj.ranking_position_case }}º Colocado </div>
                    <div class="break-name" style="font-size: 16px; height: 40px; margin-bottom: 15px; cursor: pointer;"
                      (click)="openUser(obj.user)"> {{ obj.user_obj.name }} </div>
                    <div style="font-size: 20px; font-weight: 600;"> {{ obj.score_case }} </div>
                  </div>
                </div>

              </div>
            </div>

          </ng-scrollbar>
        </div>
      </div>
    </div>


    <div class="div-ranking">
      <div class="coll-score div-border">
        <div style="color: #ef6442; font-size: calc(22px + 0.4vw); font-size: 600; margin: 0 0 50px;">
          Ranking
        </div>
        <div class="circle-borde">
          {{case_details.ranking_position_case}}°
        </div>
      </div>

      <div class="coll-score div-border">
        <div style="color: #ef6442; font-size: calc(22px + 0.4vw); font-size: 600; margin: 0 0 50px;">
          Meu score
        </div>
        <div class="circle-borde">
          <div> {{ case_details.score_case }} </div>
          <div style="font-size: 17px; margin-top: 7px; line-height: 1;"> pontos </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- <div style="width: 100%; background-color: #0a1e2d;">
    <div class="footer">
        <div class="coll-externo">
            <div class="coll-interno">
                <a href=""> Busca </a>
                <a href=""> Casos </a>
                <a href=""> Mande seu caso clínico </a>
            </div>
            <div class="coll-interno">
                <a href=""> Perfil </a>
                <a href=""> Casos feitos</a>
            </div>
            <div class="coll-interno">
                <a href=""> Dados cadastrais </a>
                <a href=""> Configurações </a>
            </div>
        </div>
        <div class="coll-social">
            <img src="../../assets/icons/facebook.png" class="img-social">
            <img src="../../assets/icons/insta.png" class="img-social">
            <img src="../../assets/icons/linkedin.png" class="img-social">
        </div>
    </div>
</div> -->
