import { Component, Input } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';

@Component({
  selector: 'modal-yes-no',
  templateUrl: './modal-yes-no.component.html',
  styleUrls: ['./modal-yes-no.component.css']
})

export class ModalYesNoComponent {

  constructor(public modalRef: MDBModalRef) { }

  action: Subject<any> = new Subject();
  text: string = "";
  title: string = "";

  ngOnInit() {

  }

  close(choice) {
    this.action.next(choice);
    this.modalRef.hide();
  }

}
