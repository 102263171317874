import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { MatRadioModule } from '@angular/material/radio';
import { HttpClientModule } from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';

/*  SCREEN   */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HomepageComponent } from './homepage/homepage.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CasesComponent } from './cases/cases.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { SendYourCaseComponent } from './send-your-case/send-your-case.component';
import { MedalsComponent } from './user-profile/medals/medals.component';
import { SettingsComponent } from './user-profile/settings/settings.component';
import { RegistrationDataComponent } from './user-profile/registration-data/registration-data.component';
import { MadeCasesComponent } from './made-cases/made-cases.component';
import { CaseDetailsComponent } from './case-details/case-details.component';
import { RankingComponent } from './user-profile/ranking/ranking.component';
import { CaseComponent } from './case/case.component';
import { CasoStartedComponent } from './caso-started/caso-started.component';
import { BodyCompositionComponent } from './caso-started/body-composition/body-composition.component';
import { SolveCaseComponent } from './caso-started/solve-case/solve-case.component';
import { ModalExplanatoryVideoComponent } from './caso-started/modal-explanatory-video/modal-explanatory-video.component';
import { CompletedCaseComponent } from './completed-case/completed-case.component';
import { ModalNextCaseComponent } from './completed-case/modal-next-case/modal-next-case.component';
import { ConfirmPasswordChangeComponent } from './confirm-password-change/confirm-password-change.component';
import { EditPasswordComponent } from './user-profile/edit-password/edit-password.component';
import { ScreenTableComponent } from './caso-started/screen-table/screen-table.component';
import { ScreenTextComponent } from './caso-started/screen-text/screen-text.component';
import { ScreenTableDetailsComponent } from './caso-started/screen-table-details/screen-table-details.component';
import { ScreenImageComponent } from './caso-started/screen-image/screen-image.component';
import { ModalMessageComponent } from './modal/modal-message/modal-message.component';
import { ModalYesNoComponent } from './modal/modal-yes-no/modal-yes-no.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomepageComponent,
    CasesComponent,
    UserProfileComponent,
    SendYourCaseComponent,
    MedalsComponent,
    SettingsComponent,
    RegistrationDataComponent,
    MadeCasesComponent,
    CaseDetailsComponent,
    RankingComponent,
    CaseComponent,
    CasoStartedComponent,
    BodyCompositionComponent,
    SolveCaseComponent,
    ModalExplanatoryVideoComponent,
    CompletedCaseComponent,
    ModalNextCaseComponent,
    ConfirmPasswordChangeComponent,
    EditPasswordComponent,
    ScreenTableComponent,
    ScreenTextComponent,
    ScreenTableDetailsComponent,
    ScreenImageComponent,
    ModalMessageComponent,
    ModalYesNoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    NgScrollbarModule,
    MatSlideToggleModule,
    MatTableModule,
    MatSelectModule,
    ChartsModule,
    MatRadioModule,
    MDBBootstrapModule.forRoot(),
    BrowserAnimationsModule,
    SlickCarouselModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
  ],
  entryComponents: [
    ModalMessageComponent,
    ModalYesNoComponent,
  ],
  providers: [],
  bootstrap: [
    AppComponent
  ]
})

export class AppModule { }
