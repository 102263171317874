import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../app.config';
import { Md5 } from "md5-typescript";
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) { }

  auth(username, password): Observable<any> {
    var passwordMD5 = Md5.init(password).toLocaleUpperCase()

    const body = new HttpParams()
      .set(`username`, username)
      .set(`password`, passwordMD5);

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })

    return this.http.post<any>(AppConfig.path + "core/auth/", body.toString(), { headers })
  }

  register(email, password): Observable<any> {
    var passwordMD5 = Md5.init(password).toLocaleUpperCase()

    let body: any = {
      email: email,
      password: passwordMD5,
    }
    return this.http.post<any>(`${AppConfig.path}core/user/`, body)
  }

}
