import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AnswerCaseService } from '../service/anwser-case.service';
import { CaseService } from '../service/case.service';
import { StorageService } from '../service/storage.service';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-case-details',
  templateUrl: './case-details.component.html',
  styleUrls: ['./case-details.component.css']
})

export class CaseDetailsComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private caseService: CaseService,
    private answerCaseService: AnswerCaseService,
    private userService: UserService,
    private storageService: StorageService,
    private router: Router,
  ) {
    this.case_id = this.route.snapshot.paramMap.get('id');
  }

  action: Subject<any> = new Subject();

  loading: boolean = true;
  case_details;

  list_state = []
  list_cities = []

  city: any = null;
  state: any = null;

  case_id;

  question: number = 0;

  case;
  qtd_user = 0;
  sinal = '+';
  score: number = 0;

  ngOnInit(): void {
    this.list_state = this.storageService.list_state;

    this.getCaseId()
    this.getRanking()
  }

  selectState(obj) {
    this.userService.getCity(obj.name, obj.code).subscribe(data => {
      this.list_cities = data.cities;
      this.filter_case()
    })
  }

  getCaseId() {
    this.loading = true;
    this.caseService.getCaseId(this.case_id).subscribe(data => {
      this.case = data;
    }, error => {
      console.log(error)
    })
  }

  openUser(id) {
    this.router.navigate([`/user/${id}`])
  }

  getRanking() {
    this.caseService.getRanking(this.case_id, null, null).subscribe(data => {
      this.case_details = data.results[0];
      this.qtd_user = this.case_details.ranking_list.length;
      this.loading = false;
    })
  }

  backCase() {
    if (this.question >= 1) {
      this.question -= 1;
      // console.log(this.case_details.user_questions[this.question])
    }
  }

  nextCase() {
    if (this.question < this.case_details.user_questions.length - 1) {
      this.question += 1;
      // console.log(this.case_details.user_questions[this.question])
    }
  }

  filter_case() {
    this.caseService.getRanking(this.case_id, this.city, this.state).subscribe(data => {
      this.case_details = data.results[0];
    })
  }

}
