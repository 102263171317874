<div class="screen">
  <div class="div-row">
    <div class="title">
      Vídeo explicativo
    </div>

    <button mat-button class="button" (click)="close()">
      Pular
    </button>
  </div>

  <div class="div-video">
    <video controls autoplay>
      <source src="{{ urlVideo }}" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  </div>
</div>
