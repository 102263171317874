<div class="screen">
  <div
    style="width: 100%; height: 50px; display: flex; align-items: center; justify-content: space-between; color: #ef6442; font-size: calc(22px + 0.4vw); margin: 0; font-weight: 600; margin-bottom: 20px;">
    {{ info?.title }}
    <!-- <div style="font-size: 17px; color: white;">
            <span> <img src="../../../assets/image/time.png" width="25px" style="margin: 0 10px 5px 0;"> </span> 3:45
        </div> -->
  </div>

  <div class="custom-scrollbar"
    style="width: 100%; overflow-x: auto; max-height: 36vh; padding: 0 10px 0 0; margin: 0 0 auto; font-size: 17px; line-height: 1.3;">

    <table mat-table [dataSource]="dataSource" style="width: 100%;">
      <div *ngFor="let coll of collAux; let c = index">

        <ng-container matColumnDef="{{ coll }}">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element; let l = index">
            <div *ngIf="matriz[c][l] != null"
              [ngStyle]="{'text-align': (matriz[c][l]?.sub_title == null && c != 0 )? 'center' : 'left'}" [ngClass]="{'div-color': matriz[c][l]?.line,
                                    'text-color': matriz[c][l].column}">

              {{ matriz[c][l] == null? '' : matriz[c][l].title }}
            </div>
          </td>
        </ng-container>

      </div>

      <tr mat-header-row *matHeaderRowDef="collAux"></tr>
      <tr mat-row *matRowDef="let row; columns: collAux;"></tr>
    </table>

  </div>

  <div class="div-reparation">
    <div *ngIf="this.barChartData[0].data.length > 0">
      <div
        style="width: 100%; height: 50px; display: flex; align-items: center;  justify-content: space-between; color: #ef6442; font-size: 18px; margin: 0; font-weight: 200; margin: 20px 0 20px;">
        Repartição das calorias:
      </div>
      <div style="display: flex; align-items: center;">
        <div style="width: 100%; display: flex; flex-direction: column;">
          <div style="display: flex; align-items: center;" *ngFor="let item of barChartLabels; let i = index">
            <div class="div-circle" [ngStyle]="{'background-color': barChartData[0].backgroundColor[i]}"></div>
            <div> {{item}} ({{ calcPercent(i) }}%) </div>
          </div>
        </div>
        <div style="width: 18vw; min-width: 100px; max-width: 170px;">
          <canvas baseChart #chart1 [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
            [legend]="barChartLegend" [chartType]="barChartType">
          </canvas>
        </div>
      </div>
    </div>
    <button mat-button class="button" (click)="pageNext()">
      {{ textButton }}
    </button>
  </div>
</div>
