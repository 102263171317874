import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AnswerCaseService } from '../service/anwser-case.service';
import { UserService } from '../service/user.service';
import { CaseService } from '../service/case.service';

@Component({
  selector: 'app-made-cases',
  templateUrl: './made-cases.component.html',
  styleUrls: ['./made-cases.component.css']
})

export class MadeCasesComponent implements OnInit {

  constructor(
    private userService: UserService,
    private answerCaseService: AnswerCaseService,
    private caseService: CaseService,
  ) { }

  displayedColumns: string[] = ['case', 'date', 'points', 'ranking', 'icons'];
  dataSource = new MatTableDataSource<any>();

  value: string;
  viewValue: string;

  text_select = "Filtro"
  search;

  user;
  list = [
    { name: 'teste 1' },
    { name: 'teste 2' }
  ];

  list_cases = []

  ngOnInit(): void {
    this.getUser()
  }

  getUser() {
    this.userService.getUser().subscribe(data => {
      this.user = data;
      this.getRankingCases()
    }, error => {
      console.log(error)
    })
  }

  getRankingCases() {
    this.caseService.getRankingCases(this.user.id).subscribe(data => {
      this.dataSource.data = data.results
      this.list_cases = data.results;
    })
  }

  getSearch(text) {
    this.dataSource.data = this.list_cases.filter(el =>
      el.case_name.toLowerCase().indexOf(text.toLowerCase()) > -1);
  }

}
