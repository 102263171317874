import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalRef } from 'angular-bootstrap-md';

@Component({
  selector: 'app-modal-next-case',
  templateUrl: './modal-next-case.component.html',
  styleUrls: ['./modal-next-case.component.css']
})

export class ModalNextCaseComponent implements OnInit {

  constructor(
    public modalRef: MDBModalRef,
    private router: Router
  ) { }

  url
  idCase;

  ngOnInit(): void {
    this.url = this.router.url;
    var aux = this.url.split('/')
    this.idCase = aux[2]

  }

  close() {
    this.modalRef.hide();
  }

  continue() {
    this.router.navigate(['case/' + this.idCase])
    this.modalRef.hide();
  }

}
