import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-body-composition',
  templateUrl: './body-composition.component.html',
  styleUrls: ['./body-composition.component.css']
})

export class BodyCompositionComponent implements OnInit {

  constructor() { }

  @Output() checkPage = new EventEmitter<void>();

  ngOnInit(): void {

  }

  pageNext() {
    this.checkPage.emit();
  }

}
