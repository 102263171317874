import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalNextCaseComponent } from '../completed-case/modal-next-case/modal-next-case.component';

@Component({
  selector: 'app-completed-case',
  templateUrl: './completed-case.component.html',
  styleUrls: ['./completed-case.component.css']
})

export class CompletedCaseComponent implements OnInit {

  constructor(public modalService: MDBModalService) { }

  modalRef: MDBModalRef;

  TextExem = ['cidade', 'estado']

  teste = [
    { img: 'https://images.unsplash.com/photo-1610294381328-9bcf7764010f?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80', ranking: '1', name: 'Nutrology Academy', score: 800 },
    { img: 'https://images.unsplash.com/photo-1608833970687-99bc4f54898d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80', ranking: '2', name: 'Nutrology Academy', score: 700 },
    { img: 'https://images.unsplash.com/photo-1610132366635-2d140c69a3ae?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=671&q=80', ranking: '3', name: 'Nutrology Academy', score: 600 },
    { img: 'https://images.unsplash.com/photo-1609999962569-f0f757358cf7?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=80', ranking: '4', name: 'Nutrology Academy', score: 500 },
    { img: 'https://images.unsplash.com/photo-1609533676311-0da452fda608?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=701&q=80', ranking: '5', name: 'Nutrology Academy', score: 400 },
    { img: 'https://images.unsplash.com/photo-1608415295464-b5de23d21f59?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80', ranking: '6', name: 'Nutrology Academy', score: 300 },
    { img: 'https://images.unsplash.com/photo-1610294381328-9bcf7764010f?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80', ranking: '7', name: 'Nutrology Academy', score: 200 },
    { img: 'https://images.unsplash.com/photo-1608833970687-99bc4f54898d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80', ranking: '8', name: 'Nutrology Academy', score: 150 },
  ]

  ngOnInit(): void {

  }

  openPopup() {
    this.modalRef = this.modalService.show(ModalNextCaseComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-sm',
      containerClass: '',
    })
  }

}
