import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-screen-table',
  templateUrl: './screen-table.component.html',
  styleUrls: ['./screen-table.component.css']
})

export class ScreenTableComponent implements OnInit {

  constructor() { }

  @Output() checkPage = new EventEmitter();
  @Input() info: any;
  @Input() screen: any;
  @Input() tamList: any;

  displayedColumns: string[] = ['medicine', 'dosage', 'score', 'time'];
  dataSource = [];
  collAux = [];

  matriz = {};

  list_table = []
  tableColl = []
  tableRow = []

  coll: number = 0;
  row: number = 0;
  cont: number = 0;

  textButton: string = 'Próximo';

  ngOnInit(): void {
    // console.log(this.info)

    if (this.screen == this.tamList - 1) {
      this.textButton = 'Solucionar caso';
    }
    else {
      this.textButton = 'Próximo';
    }

    for (let column = 0; column <= this.info.columns; column++) {
      this.matriz[column] = {}

      for (let line = 0; line <= this.info.lines; line++) {
        this.matriz[column][line] = '';
      }
    }

    if (this.info.content_case != null) {
      this.info.content_case.forEach((celula, index) => {
        if (celula.line_position == 0) {
          this.collAux.push(celula.title + index)

          this.tableColl[this.coll] = celula.title
          this.coll = this.coll + 1;
        }
        else {
          this.tableRow[this.row] = celula.title;
          this.row = this.row + 1;
        }

        this.matriz[celula?.order][celula?.line_position] = celula?.title;

        if (celula.order == 0 && celula.line_position != 0) {
          this.dataSource.push(celula)
        }
      });

      // this.createMatriz()
    }
    // console.log(this.collAux)
    // console.log(this.dataSource)
  }

  pageNext() {
    if (this.screen == this.tamList - 1) {
      this.checkPage.emit('FINISH');
    }
    else
      this.checkPage.emit(null);
  }

  createMatriz() {
    var vet = []

    this.tableRow.forEach((row, index) => {

      if (this.tableColl.length != this.cont) {
        vet[index] = row;
        this.cont = this.cont + 1;
      }
      else if (this.tableColl.length == this.cont) {
        this.list_table.push(vet);
        this.cont = 0;
        // vet = []
      }
    });

  }

}
