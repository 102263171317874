<div style="width: 100%; height: auto; min-height: calc(100vh - 180px); padding: 60px 0; background-color: #13293a;">
  <div class="screen" *ngIf="!loading">
    <div class="coll-user div-border">
      <div class="div-img">
        <img src="{{user?.profile_image != null? user?.profile_image : '../../assets/image/setup-orange.jpg'}}">
      </div>
      <div class="text-user">
        {{ user?.name == null? '' : user?.name }} <br>
        <span>
          {{ user?.email == null? '' : user?.email }}
        </span>
      </div>
      <div class="div-social">
        <img src="../../assets/icons/face_laranja.png" (click)="openSocial(user.facebook)" *ngIf="user?.facebook">
        <img src="../../assets/icons/insta_laranja.png" (click)="openSocial(user.instagram)" *ngIf="user?.instagram">
        <img src="../../assets/icons/linkedin_laranja.png" (click)="openSocial(user.linkedin)" *ngIf="user?.linkedin">
      </div>
      <div class="div-medal">
        <img src="../../assets/icons/medalha2.png">
        <img src="../../assets/icons/medalha1.png">
        <img src="../../assets/icons/medalha2.png">
        <img src="../../assets/icons/medalha1.png">
      </div>
      <!-- <div class="view-more" (click)="openMedals()">
                ver todas
            </div> -->
      <button mat-button class="button" (click)="openSettings()" *ngIf="user_id == null">
        <img src="../../assets/icons/configuracoes.png" style="max-width: 290px; min-height: 40px;">
      </button>

      <button mat-button class="button" (click)="openRegistrationData()" *ngIf="user_id == null">
        <img src="../../assets/icons/dados_cadastrais.png" style="max-width: 290px; min-height: 40px;">
      </button>

      <a *ngIf="user_id == null" style="text-decoration: underline; margin-top: 10px; color: white; font-size: 16px;"
        (click)="logoff()">
        sair
      </a>
    </div>

    <div class="coll-center ">
      <div class="coll-cases div-border custom-scrollbar" style="justify-content: flex-start;">
        <div style="width: 85%; display: flex; align-items: flex-start; justify-content: space-between; ">
          <div class="title-div">
            Casos Feitos </div>
          <div class="view-more" routerLink="/made-cases" *ngIf="user_id == null">
            ver todos </div>
        </div>
        <div *ngIf="list_cases == null || list_cases.length <= 0">
          Nenhum caso respondido.
        </div>
        <div style="width: 85%;" *ngFor="let obj of list_cases">
          <div style="width: 100%; margin: 2% 0; display: flex; justify-content: space-between;">
            <div class="div-made-cases">
              {{ obj.case_name }}
            </div>
            <div class="div-points">
              <div>{{ obj.score_case == null ? 0 : obj.score_case }}</div>
              <span>pontos</span>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="coll-attendance div-border" style="justify-content: space-between;">
                <div style="width: 85%; display: flex; align-items: flex-start; justify-content: space-between;">
                    <div class="title-div">
                        Pacientes aguardando atendimento </div>
                    <div class="view-more">
                        ver todos </div>
                </div>
                <div style="width: 85%; display: flex; justify-content: space-between;" *ngFor="let obj of teste2">
                    <div style="width: 100%; margin: 10px 0; display: flex; align-items: center !important; justify-content: space-between;">
                        <div class="div-made-cases" style="justify-content: space-between;">
                            <div>
                                Lorem Ipsum Dolor
                            </div>
                            <div style="color: #ef6442; font-weight: 600; text-align: center;">
                                70%
                            </div>
                        </div>
                        <div class="img-arrow">
                           <img src="../../assets/icons/seta_direita.png" alt="">
                        </div>
                    </div>
                </div>
            </div> -->
    </div>

    <div class="coll-right">
      <div class="coll-point">
        <div class="coll-score div-border" style="cursor: pointer;" (click)="openRanking()">
          <div style="color: #ef6442; font-size: calc(20px + 0.3vw); font-size: 600; margin: 0 0 20px;">
            Ranking
          </div>
          <div class="circle-borde" *ngIf="user.user_ranking[0].ranking_position != null">
            {{user.user_ranking[0].ranking_position}}{{ user.user_ranking[0].ranking_position != null ? 'º' : '' }}
            <!-- responda um caso para ver sua posição no ranking -->
          </div>
          <div *ngIf="user.user_ranking[0].ranking_position == null"
            style="width: 90%; text-align: center; margin: 0 auto;">
            Você não tem uma classificação no ranking até responder algum caso.
          </div>
        </div>
        <div class="coll-score div-border">
          <div style="color: #ef6442; font-size: calc(20px + 0.3vw); font-size: 600; margin: 0 0 20px;">
            Score total
          </div>
          <div class="circle-borde" *ngIf="user?.user_ranking != null && user?.user_ranking[0]?.score != null">
            {{user?.user_ranking != null ? user.user_ranking[0].score : 0}} <br>
            <div> pontos </div>
          </div>
          <div *ngIf="user?.user_ranking == null || user?.user_ranking[0]?.score == null"
            style="width: 90%; text-align: center; margin: 0 auto;">
            Você não tem score. <br>
            Responda algum caso para somar pontos para sua conta.
          </div>
        </div>
      </div>
      <div class="coll-suggestions div-border" style="justify-content: space-between;">
        <div style="width: 85%; display: flex; justify-content: space-between;">
          <div class="title-div">
            Você pode gostar desses casos </div>
          <!-- <div class="view-more">
                        ver todos </div> -->
        </div>
        <div style="width: 85%;" *ngFor="let obj of lists_recommended">
          <div
            style="width: 100%; margin: 10px 0; display: flex; align-items: center !important;  justify-content: space-between;">
            <div class="div-made-cases">
              <div>
                {{ obj.title }}
              </div>
            </div>
            <div class="img-arrow" style="cursor: pointer;">
              <img src="../../assets/icons/seta_direita.png" alt="" routerLink="/case/{{obj.id}}/">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="loading"
    style="width: 100%; height: auto; margin-top: 60px; min-height: calc(100vh - 350px); display: flex; justify-content: center; align-items: center;">
    <span class="spinner-border spinner-border-sm" style="margin: 0 auto;" role="status" aria-hidden="true"></span>
  </div>
</div>


<!-- <div style="width: 100%; background-color: #0a1e2d;">
    <div class="footer">
        <div class="coll-externo">
            <div class="coll-interno">
                <a href="/cases"> Casos </a>
                <a href="/send-case"> Mande seu caso clínico </a>
            </div>
            <div class="coll-interno">
                <a href="/user"> Perfil </a>
                <a href="/made-cases"> Casos feitos</a>
            </div>
            <div class="coll-interno">
                <a href=""> Dados cadastrais </a>
                <a href=""> Configurações </a>
            </div>
        </div>
        <div class="coll-social">
            <img src="../../assets/icons/facebook.png" class="img-social">
            <img src="../../assets/icons/insta.png" class="img-social">
            <img src="../../assets/icons/linkedin.png" class="img-social">
        </div>
    </div>
</div> -->
