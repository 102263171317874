import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-screen-image',
  templateUrl: './screen-image.component.html',
  styleUrls: ['./screen-image.component.css']
})

export class ScreenImageComponent implements OnInit {

  constructor() { }

  @Output() checkPage = new EventEmitter();
  @Input() info: any;
  @Input() screen: any;
  @Input() tamList: any;

  imagens = null;
  textButton: string = 'Próximo';

  ngOnInit(): void {
    if (this.info?.content_case != null) {
      this.imagens = this.info?.content_case[0].images
    }

    if (this.screen == this.tamList - 1) {
      this.textButton = 'Solucionar caso';
    }
    else {
      this.textButton = 'Próximo';
    }
  }

  pageNext() {
    if (this.screen == this.tamList - 1) {
      this.checkPage.emit('FINISH');
    }
    else
      this.checkPage.emit(null);
  }

}
