import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { UserService } from 'src/app/service/user.service';
import { ConstantsService } from '../../service/constants.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})

export class SettingsComponent implements OnInit {

  constructor(
    public modalRef: MDBModalRef,
    private constantsService: ConstantsService,
    private userService: UserService
  ) { }

  id;
  commercial;
  ranking;
  notification;

  config = {
    terms_of_use: '',
    privacy_policies: ''
  }

  ngOnInit(): void {
    this.getConstants();
  }

  close() {
    this.modalRef.hide();
  }

  getUser() {
    this.userService.getUser().subscribe(data => {
      this.commercial = data.allow_commercial_email;
      this.ranking = data.overall_ranking;
      this.notification = data.allow_notification;
    }, error => {
      console.log(error)
    })
  }

  getConstants() {
    this.constantsService.getConstants().subscribe(data => {
      this.config = data.config;
    }, error => {
      console.log(error)
    })
  }

  teste(posi) {
    if (posi == 0) {
      this.pacthSettingsUser(!this.commercial, null, null)
    }

    if (posi == 1) {
      this.pacthSettingsUser(null, null, !this.ranking)
    }

    if (posi == 2) {
      this.pacthSettingsUser(null, !this.notification, null)
    }
  }

  pacthSettingsUser(commercial, notification, ranking) {
    this.userService.pacthSettingsUser(this.id, commercial, notification, ranking).subscribe(data => {
      this.getUser()
    }, error => {
      console.log(error)
    })
  }

}
