<div class="screen">
  <div class="div-case">
    <div style="width: 100%; display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap;">
      <div style="color: #ef6442; font-size: calc(20px + 0.4vw); font-weight: 600; margin-bottom: 20px;">
        Casos feitos
      </div>
      <div class="div-filtro">
        <!-- <mat-form-field appearance="fill">
                    <mat-label> {{ text_select }} </mat-label>
                    <mat-select>
                        <mat-option *ngFor="let obj of list" [value]="obj.name" (click)="text_select = ''">
                            {{ obj.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->

        <div class="div-input">
          <input type="text" placeholder="Busca" [(ngModel)]="search" (keyup)="getSearch(search)">
        </div>
      </div>
    </div>

    <div class="div-table custom-scrollbar">
      <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="case">
          <th mat-header-cell *matHeaderCellDef> Casos </th>
          <td mat-cell *matCellDef="let element">
            <div class="div-border">
              {{element.case_name}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef style="width: 25%;"> Data </th>
          <td mat-cell *matCellDef="let element">
            <div class="div-border">
              {{element.created_at | date: 'dd/MM/yyyy hh:MM'}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="points">
          <th mat-header-cell *matHeaderCellDef style="width: 100px;"> Pontuação </th>
          <td mat-cell *matCellDef="let element" style="text-align: center; padding: 0;">
            <div class="div-border">
              {{element.score_case != null ? element.score_case : 0}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="ranking">
          <th mat-header-cell *matHeaderCellDef style="width: 100px;"> Ranking </th>
          <td mat-cell class="div-date" *matCellDef="let element" style="text-align: center; padding: 0;">
            <div class="div-border">
              {{element.ranking_position_case != null ? element.ranking_position_case+'°' : '-'}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="icons">
          <th mat-header-cell *matHeaderCellDef style="width: 15%;"> </th>
          <td mat-cell *matCellDef="let element" class="div-icons">
            <button mat-button routerLink="/case-detais/{{element.case_id}}">
              Abrir
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4" style="text-align: center;"> Nenhum resultado encontrado. </td>
        </tr>
      </table>

    </div>
  </div>
</div>


<!-- <div style="width: 100%; background-color: #0a1e2d;">
    <div class="footer">
        <div class="coll-externo">
            <div class="coll-interno">
                <a href="/cases"> Casos </a>
                <a href="/send-case"> Mande seu caso clínico </a>
            </div>
            <div class="coll-interno">
                <a href="/user"> Perfil </a>
                <a href="/made-cases"> Casos feitos</a>
            </div>
            <div class="coll-interno">
                <a href=""> Dados cadastrais </a>
                <a href=""> Configurações </a>
            </div>
        </div>
        <div class="coll-social">
            <img src="../../assets/icons/facebook.png" class="img-social">
            <img src="../../assets/icons/insta.png" class="img-social">
            <img src="../../assets/icons/linkedin.png" class="img-social">
        </div>
    </div>
</div> -->
