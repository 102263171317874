<div class="screen">
  <div class="div-filtro">
    <div class="title">
      Ranking
    </div>
    <div class="icon-close" (click)="close()">
      <img src="../../../assets/image/icon-close.png" width="17" height="17">
    </div>
    <div style="width: 60%; display: flex; justify-content: space-between;">
      <mat-form-field appearance="fill" style="width: 49%;">
        <mat-label style="color: #707070;">{{ state == null ? 'Estado' : ''}}</mat-label>
        <mat-select [(ngModel)]="state">
          <mat-option *ngFor="let obj of list_state" [value]="obj.name" (click)="selectState(obj)">
            {{ obj.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" style="width: 49%;">
        <mat-label style="color: #707070;">{{ city == null ? 'Cidade' : ''}}</mat-label>
        <mat-select [(ngModel)]="city">
          <mat-option *ngFor="let obj of list_cities" [value]="obj" (click)="getRankingGlobals()">
            {{ obj }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <ng-scrollbar *ngIf="list_ranking != null && list_ranking.length > 0" class="my-scrollbar">
    <div style="width: 90%; display: flex; flex-wrap: wrap;">
      <div *ngFor="let medal of list_ranking" class="div-medal">
        <div class="div-img">
          <img
            [src]="medal.user_obj.profile_image != null ? medal.user_obj.profile_image : '../../../assets/image/bege.png'">
        </div>

        <div style="width: calc(100% - 160px); display: flex; flex-direction: column;">
          <div class="text-ranking break-line" style="-webkit-line-clamp: 1;">
            {{ medal.ranking_position }}{{ medal.ranking_position != null ? 'º Colocado' : 'Sem colocação' }}
          </div>
          <div class="text-name break-line" (click)="openUser(medal?.user)" style="cursor: pointer;">
            {{ medal.user_obj.name }}
          </div>
        </div>

        <div style="width: 80px; text-align: end; font-size: 18px; font-weight: 600; color: #ef6443;">
          {{ medal.score }}
        </div>
      </div>
    </div>
  </ng-scrollbar>

  <div *ngIf="list_ranking.length == 0" style="height: 50vh; display: flex; justify-content: center; text-align: center;
        align-items: flex-start; margin-top: 30px;">
    Nenhum resultado encontrado
  </div>
</div>
