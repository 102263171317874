import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.css']
})

export class EditPasswordComponent implements OnInit {

  constructor(
    public modalRef: MDBModalRef,
    private userService: UserService
  ) { }

  action: Subject<any> = new Subject();

  user;
  password;
  oldPassword;
  loading = {
    password: false,
    button: false
  }
  msg_error: string = '';

  ngOnInit(): void {

  }

  editPassword() {
    if (this.password == null || this.password == '') {
      this.msg_error = 'Digite a nova senha'
      return false
    }
    else if (this.oldPassword == null || this.oldPassword == '') {
      this.msg_error = 'Digite a senha atual'
      return false
    }
    else {
      this.loading.password = true;
      this.msg_error = ''

      this.userService.editPassword(this.user.id, this.password, this.oldPassword).subscribe(data => {
        this.loading.password = false;
        this.loading.button = true;
        this.password = '';
        this.oldPassword = '';
      }, error => {
        console.log(error);
        this.loading.password = false;
        this.msg_error = 'Erro ao editar senha';
      })
    }
  }

  close() {
    this.action.next('edit')
    this.modalRef.hide();
  }

}
