<div class="screen">
  <div class="icon-close" (click)="close()">
    <img src="../../../assets/image/icon-close.png" width="17">
  </div>

  <div class="div-row">
    <div class="title">
      Próximo caso
    </div>
  </div>
  <div
    style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center; margin: 0;">
    <button mat-button class="button" (click)="continue()">
      Continuação
    </button>
    <button mat-button class="button">
      Caso diferente
    </button>
  </div>
</div>
