import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { StorageService } from '../service/storage.service';
import { ConstantsService } from '../service/constants.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit {

  constructor(
    private router: Router,
    private storageService: StorageService,
    private constantsService: ConstantsService
  ) {

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {

        this.url = this.router.url;
        this.accessToken = false;

        if (this.url != "/" && this.url != "/register" && this.url != "/login") {
          if (!this.storageService.isLoggedIn()) {
            this.router.navigate(['/'])
          }
          else {
            this.accessToken = true;
          }
        }
      }
    })

  }

  config = {
    facebook: '',
    instagram: '',
    youtube: '',
    linkedin: ''
  };

  url;
  isLogin;
  accessToken: boolean = false;

  ngOnInit(): void {
    this.getConstants()
  }

  getConstants() {
    this.constantsService.getConstants().subscribe(data => {
      this.config = data.config;
    }, error => {
      console.log(error)
    })
  }

}
