import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class StorageService {
  constructor(private http: HttpClient) { }

  list_state = [
    { name: 'Acre', uf: 'AC', code: '12' },
    { name: 'Alagoas', uf: 'AL', code: '27' },
    { name: 'Amapá', uf: 'AP', code: '16' },
    { name: 'Amazonas', uf: 'AM', code: '13' },
    { name: 'Bahia', uf: 'BA', code: '29' },
    { name: 'Ceará', uf: 'CE', code: '23' },
    { name: 'Espírito Santo', uf: 'ES', code: '32' },
    { name: 'Goiás', uf: 'GO', code: '52' },
    { name: 'Maranhão', uf: 'MA', code: '21' },
    { name: 'Mato Grosso', uf: 'MT', code: '51' },
    { name: 'Mato Grosso do Sul', uf: 'MS', code: '50' },
    { name: 'Minas Gerais', uf: 'MG', code: '31' },
    { name: 'Pará', uf: 'PA', code: '15' },
    { name: 'Paraíba', uf: 'PB', code: '25' },
    { name: 'Paraná', uf: 'PR', code: '41' },
    { name: 'Pernambuco', uf: 'PE', code: '26' },
    { name: 'Piauí', uf: 'PI', code: '22' },
    { name: 'Rio de Janeiro', uf: 'RJ', code: '33' },
    { name: 'Rio Grande do Norte', uf: 'RN', code: '24' },
    { name: 'Rio Grande do Sul', uf: 'RS', code: '43' },
    { name: 'Rondônia', uf: 'RO', code: '11' },
    { name: 'Roraima', uf: 'RR', code: '14' },
    { name: 'Santa Catarina', uf: 'SC', code: '42' },
    { name: 'São Paulo', uf: 'SP', code: '35' },
    { name: 'Sergipe', uf: 'SE', code: '28' },
    { name: 'Tocantins', uf: 'TO', code: '17' },
    { name: 'Distrito Federal', uf: 'DF', code: '53' }
  ]

  private ACCESS_TOKEN: string = "ACCESSTOKEN";

  loginSubject = new Subject<String>();
  logoutSubject = new Subject<String>();

  login(token): void {
    localStorage.setItem(this.ACCESS_TOKEN, token)
  }

  getToken(): string {
    return localStorage.getItem(this.ACCESS_TOKEN)
  }

  logout(): void {
    localStorage.removeItem(this.ACCESS_TOKEN)
  }

  isLoggedIn(): boolean {
    if (localStorage.getItem(this.ACCESS_TOKEN) == null) {
      return false;
    }
    else {
      return true;
    }
  }

}
