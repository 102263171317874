import { Component, OnInit, Output, EventEmitter, QueryList, ViewChildren, Input } from '@angular/core';
import { BaseChartDirective, MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { AnswerCaseService } from 'src/app/service/anwser-case.service';
import { ModalExplanatoryVideoComponent } from '../modal-explanatory-video/modal-explanatory-video.component';

@Component({
  selector: 'app-solve-case',
  templateUrl: './solve-case.component.html',
  styleUrls: ['./solve-case.component.css']
})

export class SolveCaseComponent implements OnInit {

  constructor(
    private modalService: MDBModalService,
    private answerCaseService: AnswerCaseService,
  ) { }

  @Input() case: any;
  modalRef: MDBModalRef;
  @Output() checkPage = new EventEmitter<void>();

  // Pie
  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;

  barChartData: ChartDataSets[] = [
    {
      data: [58, 0, 42],
      label: 'Real',
      backgroundColor: ['#EFAA42', '#602439', '#00B4C1'],
      weight: 1,
    },
  ];

  biggest = 30;

  barChartLabels: Label[] = ['D', 'C', 'B', 'A'];
  barChartType: ChartType = 'pie';
  barChartLegend = false;
  barChartOptions: ChartOptions = {};

  answeSelect;
  my_answers = []
  page_answers: number = 0;

  text1: string;
  text2: string;

  list_answe = []
  page: number;
  question: number = 0;

  messageErro: string = '';

  ngOnInit(): void {
    this.page = 1;
    this.text1 = 'Iogurte desnatado (140g)';
    this.text2 = '6,6';

    console.log(this.case)
  }

  ordenarList(a, b) {
    if (a.order < b.order)
      return -1;
    if (a.order > b.order)
      return 1;
    return 0;
  }

  backAnswer() {
    // console.log(this.my_answers)

    if (this.page_answers > 0) {
      this.page_answers -= 1;
    }
  }

  nextAnswer() {
    // console.log(this.my_answers)

    if (this.page_answers < this.case.questions.length - 1) {
      this.page_answers += 1;
    }
  }

  nextQuestion() {
    this.messageErro = '';
    if (this.answeSelect != null) {
      this.my_answers.push(this.answeSelect)
      if (this.question < this.case.questions.length - 1) { this.question += 1; }
    }
  }

  sendCase() {
    this.messageErro = '';

    if (this.answeSelect != null) {
      this.my_answers.push(this.answeSelect)

      this.my_answers.forEach(element => {
        this.answerCaseService.postRespond(element).subscribe(data => {
          this.answeSelect = null
          this.page = 3
        },
          error => {
            console.log(error)
            if (error?.error?.detail != null) {
              this.messageErro = error?.error?.detail;
            }
            else
              this.messageErro = "Não foi possivel responder o caso agora, tente novamente mais tarde.";
          })
      });
    }
  }

  ngAfterViewInit() {
    this.charts.forEach((child, index) => {

      this.barChartOptions = {
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
        cutoutPercentage: 50,
        responsive: true,
        rotation: Math.PI,
        circumference: 1 * Math.PI,
        maintainAspectRatio: false,
        tooltips: { enabled: true },
        scales: {
          yAxes: [{
            ticks: { beginAtZero: true }, gridLines: { color: 'transparent' }, display: false
          }],
          xAxes: [{
            display: false,
            ticks: {
              beginAtZero: true,
              max: (this.biggest * 1.15)
            }
          }],
        },
        plugins: {
          datalabels: {
            font: {
              size: 16, weight: 600, family: 'Squada One'
            },
            color: 'white',
            formatter: function (value, context) {
              //let aux: any = context.chart.data.datasets[1].data[context.dataIndex]
              if (context.datasetIndex == 0) {
                return null;
              }
              else {
                return `${value}%`;
              }

            }
          }
        }

      }

      child.chart.options = this.barChartOptions;
      child.chart.update()
      //console.log(child)
    });

  }

  openVideo() {
    this.modalRef = this.modalService.show(ModalExplanatoryVideoComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
    })
  }

}
