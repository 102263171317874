import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../app.config';
import { StorageService } from './storage.service';


@Injectable({
  providedIn: 'root'
})

export class CaseService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) { }

  getCases(): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('Authorization', `token ${this.storageService.getToken()}`)
    };
    return this.http.get<any>(AppConfig.path + "core/case/", options)
  }

  getRankingCases(id_user): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('Authorization', `token ${this.storageService.getToken()}`),
      params: new HttpParams()
        .set('id', id_user)
    }
    return this.http.get<any>(AppConfig.path + "core/user/cases-list/", options)
  }

  getRanking(id_case, city, state): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('Authorization', `token ${this.storageService.getToken()}`),
      params: new HttpParams()
        .set('case', id_case)
    }
    if (city != null && city != "") {
      options.params = options.params.append(`city`, city)
    }
    if (state != null && state != "") {
      options.params = options.params.append(`state`, state)
    }

    return this.http.get<any>(AppConfig.path + `core/user/total-score-case/`, options)
  }

  getCaseId(id): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('Authorization', `token ${this.storageService.getToken()}`)
    };
    return this.http.get<any>(AppConfig.path + `core/case/${id}/`, options)
  }

  postCase(title, description, patient, category): Observable<any> {

    let body = new HttpParams()
      .set(`title`, title)
      .set(`description`, description)
      .set(`patient`, patient)
      .set(`category`, category)

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('Authorization', `token ${this.storageService.getToken()}`)

    return this.http.post<any>(AppConfig.path + `core/case/`, body, { headers })
  }

  postRespond(answer_case): Observable<any> {

    let body = new HttpParams()
      .set(`answer_case`, answer_case)

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('Authorization', `token ${this.storageService.getToken()}`)

    return this.http.post<any>(AppConfig.path + `core/case/respond/`, body, { headers })
  }

  patchCase(id, title): Observable<any> {

    let body = new HttpParams()
      .set(`title`, title)

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('Authorization', `token ${this.storageService.getToken()}`)

    return this.http.patch<any>(AppConfig.path + `core/case/${id}`, body, { headers })
  }

  deleteCase(id, name): Observable<any> {

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('Authorization', `token ${this.storageService.getToken()}`),
      params: new HttpParams()
        .set('name', name)
    };

    return this.http.delete<any>(AppConfig.path + `core/case/${id}`, options)
  }

}
