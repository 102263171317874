import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/service/user.service';
import { EMPTY } from 'rxjs';
import { expand, map } from 'rxjs/operators';
import { CompressorService } from 'src/app/service/compressor.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-registration-data',
  templateUrl: './registration-data.component.html',
  styleUrls: ['./registration-data.component.css']
})

export class RegistrationDataComponent implements OnInit {

  constructor(
    public modalRef: MDBModalRef,
    private userService: UserService,
    private compressor: CompressorService,
    private storageService: StorageService
  ) { }

  action: Subject<any> = new Subject();

  user;

  name: string = null;
  email: string = null;
  image: string = null;
  specialty: string = null;
  state: string = null;
  city: string = null;
  social = {
    facebook: null,
    instagram: null,
    linkedin: null
  }
  social_check: boolean = false;
  user_check;

  list_state = []
  list_cities = []

  compressed_image = []

  ngOnInit(): void {
    this.list_state = this.storageService.list_state;
    if (this.user != null) {

      this.user_check = this.user;
      this.name = this.user?.name;
      this.email = this.user?.email;
      this.state = this.user?.state;
      this.city = this.user?.city;
      this.image = this.user?.profile_image;
      this.specialty = this.user?.specialty;

      this.social.facebook = this.user.facebook;
      this.social.instagram = this.user.instagram;
      this.social.linkedin = this.user.linkedin;

      var aux_state = this.list_state.filter(el =>
        el.name.toLowerCase().indexOf(this.state.toLowerCase()) > -1
      );
      this.selectState(aux_state[0])
    }
  }

  close() {
    this.pacthSettingsUser()
  }

  pacthSettingsUser() {
    if (this.name == this.user_check.name &&
      this.email == this.user_check.email &&
      this.state == this.user_check.state &&
      this.city == this.user_check.city &&
      this.image == this.user_check.image) {
      this.modalRef.hide();
    }
    else {
      this.userService.editUser(this.user.id, this.name, this.email, this.specialty, this.state,
        this.city, this.image, this.social).subscribe(data => {
          this.action.next('loading')
          this.modalRef.hide();
        }, error => {
          console.log(error)
        })
    }
  }

  selectState(obj) {
    this.userService.getCity(obj.name, obj.code).subscribe(data => {
      this.list_cities = data.cities;
    })
  }

  editPassword() {
    this.action.next('edit')
    this.modalRef.hide();
  }

  async onFileChanged(event) {
    if (event.target.files && event.target.files[0]) {
      var target = event.target.files
      const compress = this.recursiveCompress(target[0], 0, target).pipe(
        expand(res => {
          return res.index > res.array.length - 1
            ? EMPTY
            : this.recursiveCompress(target[res.index], res.index, target);
        }),
      );
      compress.subscribe(res => {
        if (res.index > res.array.length - 1) {
          this.image = this.compressed_image[0]
          this.user.profile_image = this.compressed_image[0]
          // this.items[indexX].image = this.compressed_image[0]
          // this.items[indexX]['edited'] = true;
          this.compressed_image = []

        }
      });
    }
  }

  recursiveCompress = (image: File, index, array) => {
    return this.compressor.compress(image).pipe(
      map(response => {
        this.compressed_image.push(response)
        return {
          data: response,
          index: index + 1,
          array: array,
        };
      }),
    );
  }

}
