<div class="screen">
  <div class="icon-close" (click)="close()">
    <img src="../../../assets/image/icon-close.png" width="17">
  </div>

  <div class="div-row">
    <div style="display: flex; flex-direction: column; align-items: center;">
      <div class="img-user">
        <img src="{{user?.profile_image != null? user?.profile_image : '../../../assets/image/setup-orange.jpg'}}">
      </div>
      <button mat-button class="bton" style="background-color: #ef6443;" (click)="fileInput.click()">
        Trocar foto
      </button>
      <input type="file" #fileInput style="display: none; " accept="image/*" (change)="onFileChanged($event)">

      <button mat-button class="bton" style="background-color: #602439;" (click)="editPassword()">
        Trocar senha
      </button>
      <div
        style="width: 220px; margin: 50px 0 0 0; font-size: 18px; font-weight: 600; color: #707070; text-align: center;">
        Adicionar redes sociais
      </div>
      <div class="div-social" *ngIf="!social_check">
        <img src="../../../assets/icons/facebook_preto.png">
        <img src="../../../assets/icons/insta_preto.png">
        <img src="../../../assets/icons/linkedin_preto.png">
        <img src="../../../assets/icons/adicionar.png" style="cursor: pointer;" (click)="social_check = !social_check">
      </div>

      <div class="div_coll" *ngIf="social_check">
        <div class="row_social">
          <img src="../../../assets/icons/facebook_preto.png">
          <input mat-input type="text" placeholder="facebook.com/" [(ngModel)]="social.facebook">
        </div>

        <div class="row_social">
          <img src="../../../assets/icons/insta_preto.png">
          <input mat-input type="text" placeholder="instagram.com/" [(ngModel)]="social.instagram">
        </div>

        <div class="row_social">
          <img src="../../../assets/icons/linkedin_preto.png">
          <input mat-input type="text" placeholder="linkedin.com/" [(ngModel)]="social.linkedin">
        </div>
      </div>
    </div>

    <div class="linha"></div>

    <div style="width: 100%; display: flex; flex-direction: column;">
      <div class="title">
        Dados Cadastrais
      </div>
      <div class="div-input">
        <div style="margin-left: 25px;">Nome</div>
        <input mat-input type="text" placeholder="Nome" [(ngModel)]="name">
      </div>
      <div class="div-input">
        <div style="margin-left: 25px;">E-mail</div>
        <input mat-input type="text" placeholder="E-mail" [(ngModel)]="email">
      </div>
      <div class="div-input">
        <div style="margin-left: 25px;">Especialidade</div>
        <input mat-input type="text" placeholder="Especialidade" [(ngModel)]="specialty">
      </div>
      <div style="width: 100%; display: flex; justify-content: space-between;">

        <div class="div-input" style="width: 47%;">
          <div style="margin-left: 25px;">Estado</div>

          <mat-form-field class="div-select">
            <mat-select [(ngModel)]="state" style="width: 100%;">
              <mat-option *ngFor="let obj of list_state" [value]="obj.name" (click)="selectState(obj)"
                style="width: 100%;" placeholder="Estado">
                {{obj.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="div-input" style="width: 47%;">
          <div style="margin-left: 25px;">Cidade</div>

          <mat-form-field class="div-select">
            <mat-select [(ngModel)]="city" style="width: 100%;">
              <mat-option *ngFor="let obj of list_cities" [value]="obj" style="width: 100%;" placeholder="Cidade">
                {{obj}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>
    </div>
  </div>

</div>
