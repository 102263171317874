<div class="screen">
  <div
    style="width: 100%; height: 50px; display: flex; align-items: center;  justify-content: space-between; color: #ef6442; font-size: calc(22px + 0.4vw); margin: 0; font-weight: 600; margin-bottom: 20px;">
    Composição corporal
    <!-- <div style="font-size: 17px; color: white;">
      <span> <img src="../../../assets/image/time.png" width="25px" style="margin: 0 10px 5px 0;"> </span> 3:45
    </div> -->
  </div>
  <div class="div-row">
    <div class="div-img"> </div>
    <div class="div-img"> </div>
  </div>
  <div style="width: 100%; display: flex; justify-content: flex-end; align-items: flex-end;">
    <button mat-button class="button" (click)="pageNext()">
      Solucionar caso
    </button>
  </div>
</div>
