import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { EMPTY } from 'rxjs';
import { expand, map } from 'rxjs/operators';
import { CompressorService } from 'src/app/service/compressor.service';
import { ModalMessageComponent } from '../modal/modal-message/modal-message.component';
import { SuggestionCaseService } from '../service/suggestion-case.service';

@Component({
  selector: 'app-send-your-case',
  templateUrl: './send-your-case.component.html',
  styleUrls: ['./send-your-case.component.css']
})

export class SendYourCaseComponent implements OnInit {

  constructor(
    private router: Router,
    private compressor: CompressorService,
    private suggestionCaseService: SuggestionCaseService,
    private modalService: MDBModalService,
  ) { }

  modalRef: MDBModalRef;

  list_case = [
    { title: 'Queixa', value: null, type: 'complain' },
    { title: 'Recordatório alimentar', value: null, type: 'food_recall' },
    { title: 'Bioquímica', value: null, type: 'biochemistry' },
    { title: 'Composição corporal', value: null, type: 'body_composition' },
    { title: 'Exame físico e HPP', value: null, type: 'exams' },
    { title: 'Remédio / suplementação', value: null, type: 'medicine' },
    { title: 'Exercício', value: null, type: 'exercise' },
    { title: 'Sono', value: null, type: 'sleep' }
  ]

  list_send = [];
  imagesSelect = []
  imagesName = []

  errorMessage: string = '';

  checked: boolean = false;

  compressed_image = [];

  ngOnInit(): void {
    // this.getSuggestionCase()
  }

  getSuggestionCase() {
    this.suggestionCaseService.getSuggestionCase().subscribe(data => {
      // console.log(data)
    })
  }

  remove(posi) {
    this.imagesSelect.splice(posi, 1)
    this.imagesName.splice(posi, 1)
  }

  send() {
    this.errorMessage = '';
    let has_error = false

    if (!this.checked) {
      this.errorMessage = "Por favor, você precisa confirmar que as informações não são verdadeiras."
    }
    else {
      this.list_case.forEach((element, index) => {
        this.list_send[element.type] = element.value

        if (element.value == null) {
          this.errorMessage = '*Por favor, preencha o campo ' + element.title + '.';
          has_error = true;
          return
        }
        else if (index == this.list_case.length - 1 && !has_error) {

          this.list_send['images'] = []
          this.imagesSelect.forEach(element => {
            this.list_send['images'].push({ image: element })
          });

          this.postSuggestionCase()

        }
      });
    }

  }

  postSuggestionCase() {
    this.suggestionCaseService.postSuggestionCase(this.list_send).subscribe(data => {
      // console.log(data)
      this.list_case.forEach(element => {
        element.value = null
      });

      this.openPopupMessage()
      // this.router.navigate(["/user"])
    })
  }

  navigateTop() {
    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

    if (supportsNativeSmoothScroll == true) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      window.scroll(0, 0);
    }
  }

  async onFileChanged(event) {
    if (event.target.files && event.target.files[0]) {
      var target = event.target.files

      for (let index = 0; index < target.length; index++) {
        this.imagesName.push(target[index].name)
      }

      const compress = this.recursiveCompress(target[0], 0, target).pipe(
        expand(res => {
          return res.index > res.array.length - 1
            ? EMPTY
            : this.recursiveCompress(target[res.index], res.index, target);
        }),
      );
      compress.subscribe(res => {
        if (res.index > res.array.length - 1) {
          // this.image = this.compressed_image[0]

          this.compressed_image.forEach(el => {
            this.imagesSelect.push(el)
          })

          this.compressed_image = []
        }
      });
    }
  }

  recursiveCompress = (image: File, index, array) => {
    return this.compressor.compress(image).pipe(
      map(response => {
        this.compressed_image.push(response)

        // console.log(array)
        // array.forEach(el => {
        //     this.imagesName.push(el.name)
        // });

        return {
          data: response,
          index: index + 1,
          array: array,
        };
      }),
    );
  }

  openPopupMessage() {
    this.modalRef = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
      data: {
        text: "Enviado com sucesso!"
      }
    })
  }

}
