import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { BaseChartDirective } from 'angular-bootstrap-md';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label, MultiDataSet, SingleDataSet } from 'ng2-charts';

@Component({
  selector: 'app-screen-table-details',
  templateUrl: './screen-table-details.component.html',
  styleUrls: ['./screen-table-details.component.css']
})

export class ScreenTableDetailsComponent implements OnInit {

  constructor() { }

  @Output() checkPage = new EventEmitter();
  @Input() info: any;
  @Input() screen: any;
  @Input() tamList: any;

  // Pie
  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;

  dataSource = [];
  collAux = [];

  barChartData: ChartDataSets[] = [
    {
      data: [],
      label: '',
      backgroundColor: ['#EFAA42', '#602439', '#00B4C1', '#ef6442'],
      weight: 1,
    },
  ];

  biggest = 30;
  sum = 0;

  barChartLabels: Label[] = [];
  barChartType: ChartType = 'pie';
  barChartLegend = false;
  barChartOptions: ChartOptions = {};

  text1: string;
  text2: string;

  matriz = [];
  vetAux = [];

  textButton: string = 'Próximo';

  ngOnInit(): void {
    console.log('entrou')
    console.log(this.info)

    if (this.screen == this.tamList - 1) {
      this.textButton = 'Solucionar caso';
    }
    else {
      this.textButton = 'Próximo';
    }

    for (let column = 0; column <= this.info.columns; column++) {
      this.matriz[column] = {}
      // this.collAux.push('coll'+column)

      for (let line = 0; line <= this.info.lines; line++) {
        this.matriz[column][line] = null;
      }
    }

    if (this.info.content_case != null) {
      this.info.content_case.forEach((celula, index) => {

        if (celula.line_position == 0) {
          this.collAux.push(celula.title + index)
          // this.collAux.push('coll'+index)
          if (celula.order != 0) {
            this.barChartLabels.push(celula.title)
          }
        }

        if (celula.line_position != null)
          this.matriz[celula?.order][celula?.line_position] = celula;

        // if(celula.line_position == null)
        //   this.matriz[celula?.order][0] = celula;

        if (celula.order == 0) {
          this.dataSource.push(celula)
        }

        if (celula.line && celula.order != 0) {
          if (this.barChartData[0].data[celula.order - 1] == null)
            this.barChartData[0].data[celula.order - 1] = 0

          var num = celula.title
          // num = num.replace(',', '.')
          // num = num.replace('g', '')
          // num = num.replace('kcal', '')

          this.barChartData[0].data[celula.order - 1] += +parseFloat(num) as any

          this.sum += +parseFloat(num)
        }
      });

      // this.dataSource = this.matriz
    }
  }

  calcPercent(i) {
    let val = this.barChartData[0].data[i] as number
    return (val * 100 / this.sum).toFixed(2)
  }

  pageNext() {
    if (this.screen == this.tamList - 1) {
      this.checkPage.emit('FINISH');
    }
    else
      this.checkPage.emit(null);
  }

  ngAfterViewInit() {
    this.charts.forEach((child, index) => {

      this.barChartOptions = {
        cutoutPercentage: 50,
        responsive: true,
        rotation: Math.PI,
        circumference: 1 * Math.PI,
        maintainAspectRatio: false,
        tooltips: { enabled: true },
        scales: {
          yAxes: [{
            ticks: { beginAtZero: true }, gridLines: { color: 'transparent' }, display: false
          }],
          xAxes: [{
            display: false,
            ticks: {
              beginAtZero: true,
              max: (this.biggest * 1.15)
            }
          }],
        },
        plugins: {
          datalabels: {
            font: {
              size: 16, weight: 600, family: 'Squada One'
            },
            color: 'white',
            formatter: function (value, context) {
              //let aux: any = context.chart.data.datasets[1].data[context.dataIndex]
              if (context.datasetIndex == 0) {
                return null;
              }
              else {
                return `${value}%`;
              }
            }
          }
        }
      }

      child.chart.options = this.barChartOptions;
      child.chart.update()
      //console.log(child)
    });
  }

}
