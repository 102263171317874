<div class="modal-content">

  <div class="modal-header">
    <img src="/assets/icons/icon-close.png" class="modal-close" (click)="modalRef.hide()">

    <h4 class="modal-title">
      {{ title }}
    </h4>
  </div>

  <div class="modal-body">
    {{ text }}

    <div style="width: 100%; margin-top: 20px;">
      <button mat-raised-button class="btn-orange" (click)="close('no')">
        NÃO
      </button>

      <button mat-raised-button class="btn-white" (click)="close('yes')">
        SIM
      </button>
    </div>
  </div>

</div>
