import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NavbarComponent } from './navbar/navbar.component';
import { HomepageComponent } from './homepage/homepage.component';
import { CasesComponent } from './cases/cases.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { SendYourCaseComponent } from './send-your-case/send-your-case.component';
import { MadeCasesComponent } from './made-cases/made-cases.component';
import { CaseDetailsComponent } from './case-details/case-details.component';
import { CaseComponent } from './case/case.component';
import { CasoStartedComponent } from './caso-started/caso-started.component';
import { CompletedCaseComponent } from './completed-case/completed-case.component';
import { ConfirmPasswordChangeComponent } from './confirm-password-change/confirm-password-change.component';

const routes: Routes = [
  {
    path: '', component: NavbarComponent,
    children: [
      { path: 'register', component: HomepageComponent },
      { path: 'login', component: HomepageComponent },
      { path: 'cases', component: CasesComponent },
      { path: 'user', component: UserProfileComponent },
      { path: 'user/:id', component: UserProfileComponent },
      { path: 'send-case', component: SendYourCaseComponent },
      { path: 'made-cases', component: MadeCasesComponent },
      { path: 'case-detais/:id', component: CaseDetailsComponent },
      { path: 'case/:id', component: CaseComponent },
      { path: 'case/:id/case-started', component: CasoStartedComponent },
      { path: 'case/:id/completed-case', component: CompletedCaseComponent },
      { path: 'change-password', component: ConfirmPasswordChangeComponent },
      { path: '', component: HomepageComponent },
    ]
  },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
