import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../app.config';
import { StorageService } from './storage.service';
import { CustomEncoder } from './custom-encoder';

@Injectable({
  providedIn: 'root'
})

export class PatientService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) { }

  getPatient(): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('Authorization', `token ${this.storageService.getToken()}`),
    };
    return this.http.get<any>(AppConfig.path + "core/patient/", options)
  }

  getPatientId(id): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('Authorization', `token ${this.storageService.getToken()}`),
    };
    return this.http.get<any>(AppConfig.path + `core/patient/${id}`, options)
  }

  postCase(name, age, gender, weight, height, image): Observable<any> {

    let body = new HttpParams({ encoder: new CustomEncoder() })
      .set(`name`, name)
      .set(`age`, age)
      .set(`gender`, gender)
      .set(`weight`, weight)
      .set(`height`, height)
      .set(`image`, encodeURI(image))

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('Authorization', `token ${this.storageService.getToken()}`)

    return this.http.post<any>(AppConfig.path + `core/patient/`, body, { headers })
  }

  patchCase(id, title): Observable<any> {

    let body = new HttpParams({ encoder: new CustomEncoder() })
      .set(`title`, title)

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('Authorization', `token ${this.storageService.getToken()}`)

    return this.http.patch<any>(AppConfig.path + `core/info-case/${id}`, body, { headers })
  }

  deleteCase(id, name): Observable<any> {

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('Authorization', `token ${this.storageService.getToken()}`),
      params: new HttpParams()
        .set('name', name)
    };

    return this.http.delete<any>(AppConfig.path + `core/info-case/${id}`, options)
  }

}
