import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../app.config';
import { Md5 } from "md5-typescript";
import { StorageService } from './storage.service';
import { CustomEncoder } from './custom-encoder';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) { }

  getUser(): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('Authorization', `token ${this.storageService.getToken()}`)
    };
    return this.http.get<any>(AppConfig.path + "core/user/", options)
  }

  getUserId(id): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('Authorization', `token ${this.storageService.getToken()}`)

    return this.http.get<any>(AppConfig.path + `core/user/${id}/`, { headers })
  }

  getRankingGlobals(city, state): Observable<any> {

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('Authorization', `token ${this.storageService.getToken()}`),
      params: new HttpParams()
    };

    if (city != null && city != "") {
      options.params = options.params.append(`city`, city)
    }
    if (state != null && state != "") {
      options.params = options.params.append(`state`, state)
    }

    return this.http.get<any>(AppConfig.path + "core/user/ranking/", options)
  }

  getAswerUser(id_user): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('Authorization', `token ${this.storageService.getToken()}`),
      params: new HttpParams()
        .set('user', id_user)
    };
    return this.http.get<any>(AppConfig.path + "core/user/answer-user/", options)
  }

  editPassword(id, password, old_password): Observable<any> {
    var passwordMD5 = Md5.init(password).toLocaleUpperCase()
    var old_passwordMD5 = Md5.init(old_password).toLocaleUpperCase()

    const body = new HttpParams()
      .set(`password`, passwordMD5)
      .set(`old_password`, old_passwordMD5);

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('Authorization', `token ${this.storageService.getToken()}`)

    return this.http.patch<any>(AppConfig.path + `core/user/${id}/`, body.toString(), { headers })
  }

  editUser(id, name, email, specialty, state, city, image, social): Observable<any> {

    let body = new HttpParams({ encoder: new CustomEncoder() })
      .set(`name`, name)
      .set(`email`, email)
      .set(`state`, state)
      .set(`city`, city)

    if (social.facebook != null) {
      body = body.append(`facebook`, social.facebook)
    }
    if (social.instagram != null) {
      body = body.append(`instagram`, social.instagram)
    }
    if (social.linkedin != null) {
      body = body.append(`linkedin`, social.linkedin)
    }

    if (image != null && image.substring(0, 4) != 'https') {
      body = body.append(`profile_image`, encodeURI(image))
    }

    if (specialty != null) {
      body = body.append(`specialty`, specialty)
    }

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('Authorization', `token ${this.storageService.getToken()}`)

    return this.http.patch<any>(AppConfig.path + `core/user/${id}/`, body.toString(), { headers })
  }

  pacthSettingsUser(id, email, notification, ranking): Observable<any> {

    let body = new HttpParams()

    if (email != null) {
      body = body.append(`allow_commercial_email`, email)
    }
    if (notification != null) {
      body = body.append(`allow_notification`, notification)
    }
    if (ranking != null) {
      body = body.append(`overall_ranking`, ranking)
    }

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('Authorization', `token ${this.storageService.getToken()}`)

    return this.http.patch<any>(`${AppConfig.path}core/user/${id}/`, body.toString(), { headers })
  }

  getCity(state, codeUf) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
      params: new HttpParams()
        .set('state_code', codeUf)
    };

    return this.http.get<any>(AppConfig.path + "core/cities/", options)
  }

}
