import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalRef } from 'angular-bootstrap-md';

@Component({
  selector: 'modal-message',
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.css']
})

export class ModalMessageComponent {

  constructor(
    public modalRef: MDBModalRef,
    private router: Router
  ) { }

  text: string;
  is_image: boolean = false;

  ngOnInit() {

  }

  close_popup() {
    this.modalRef.hide()
  }

}
