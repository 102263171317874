<div class="modal-content">

  <div class="modal-header">

    <div class="icon-close" (click)="close_popup()">
      <img src="../../../assets/image/icon-close.png" width="17">
    </div>

    <!-- <img src="../../../assets/image/icon-close.png" class="close-btn" (click)="close_popup()" > -->

    <div class="modal-title" *ngIf="!is_image">
      <b>{{text}}</b> <br>
    </div>

    <div *ngIf="is_image" style="width: 100%; margin: 20px 0;">
      <img [src]="text" style="width: 100%; max-height: 70vh; object-fit: contain;">
    </div>

  </div>

</div>
