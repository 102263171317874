<div class="screen">
  <div
    style="width: 100%; height: 50px; display: flex; align-items: center;  justify-content: space-between; color: #ef6442; font-size: calc(22px + 0.4vw); margin: 0; font-weight: 600; margin-bottom: 20px;">
    {{ info?.title }}
    <!-- <div style="font-size: 17px; color: white;">
            <span> <img src="../../../assets/image/time.png" width="25px" style="margin: 0 10px 5px 0;"> </span> 3:45
        </div> -->
  </div>
  <div class="custom-scrollbar"
    style="width: 100%; overflow-x: auto; max-height: 55vh; padding: 0 10px 0 0; margin: 0 0 auto; font-size: 17px; line-height: 1.3;">

    <div class="div-table">
      <table mat-table [dataSource]="dataSource">
        <div *ngFor="let coll of collAux; let c = index">

          <ng-container matColumnDef="{{ coll }}">
            <th mat-header-cell *matHeaderCellDef> {{ tableColl[c] }} </th>
            <td mat-cell *matCellDef="let element; let l = index">
              {{ matriz[c][l+1]==null? '' : matriz[c][l+1] }}
            </td>
          </ng-container>

        </div>

        <tr mat-header-row *matHeaderRowDef="collAux"></tr>
        <tr mat-row *matRowDef="let row; columns: collAux;"></tr>
      </table>

    </div>

  </div>
  <div style="width: 100%; display: flex; justify-content: flex-end; align-items: flex-end;">
    <button mat-button class="button" (click)="pageNext()">
      {{ textButton }}
    </button>
  </div>
</div>
