<div class="screen">
  <div
    style="width: 100%; height: 50px; display: flex; align-items: center;  justify-content: space-between; color: #ef6442; font-size: calc(22px + 0.4vw); margin: 0; font-weight: 600; margin-bottom: 20px;">
    {{ info?.title }}
    <!-- <div style="font-size: 17px; color: white;">
            <span> <img src="../../../assets/image/time.png" width="25px" style="margin: 0 10px 5px 0;"> </span> 3:45
        </div> -->
  </div>
  <div style="width: 100%; max-width: 900px; display: flex; justify-content: space-between; align-items: center;
        overflow-x: auto; padding: 0 10px 0 0; margin: 0 0 auto; font-size: 17px; line-height: 1.3;">

    <div *ngFor="let obj of imagens" class="image">
      <img [src]="obj.image">
    </div>

    <div *ngIf="imagens == null"
      style="width: 100%; color: #ef6442; margin: 20px 0; font-size: calc(18px + 0.3vw); text-align: center;">
      Nenhuma Imagem encontrada.
    </div>

  </div>
  <div style="width: 100%; display: flex; justify-content: flex-end; align-items: flex-end;">
    <button mat-button class="button" (click)="pageNext()">
      {{ textButton }}
    </button>
  </div>
</div>
