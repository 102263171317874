import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Case } from '../models/case';
import { Patient } from '../models/patient';
import { CaseService } from '../service/case.service';

@Component({
  selector: 'app-caso-started',
  templateUrl: './caso-started.component.html',
  styleUrls: ['./caso-started.component.css']
})

export class CasoStartedComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private caseService: CaseService
  ) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.case_id = this.route.snapshot.paramMap.get('id');
      }
    })
  }

  loading: boolean = false;

  url
  case_id;
  screenSelect: number = 1;
  case: Case;
  pacient: Patient;
  info_case;

  infoSelect;

  position: number = 0;
  typePage: string;

  ngOnInit(): void {
    // console.log(this.case_id)
    this.screenSelect = -1
    this.getCase()
  }

  next(text) {
    if (text == null) {
      if (this.info_case[this.position + 1] != null) {
        this.position = this.position + 1;
        this.typePage = this.info_case[this.position].type_case
        this.infoSelect = this.info_case[this.position];

        this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 100);
      }
    }
    else {
      this.screenSelect = 8;
      this.typePage = null;
      this.infoSelect = null;
      this.position = null;
    }
  }

  getCase() {
    this.loading = true;

    this.caseService.getCaseId(this.case_id).subscribe(data => {
      // console.log('Info ', data)

      this.case = data;
      this.pacient = data.patient;
      this.info_case = data.info_case;
      if (data.info_case != null) {
        this.typePage = data?.info_case[0]?.type_case
        this.infoSelect = data?.info_case[0];
      }

      this.loading = false;
    }, error => {
      console.log(error)
    })
  }

  checkType(item, posi) {
    this.screenSelect = -1
    this.position = posi;
    this.typePage = item.type_case
    this.infoSelect = item;

    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 100);
  }

}
