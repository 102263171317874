import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { CaseService } from '../service/case.service';
import { ConstantsService } from '../service/constants.service';

@Component({
  selector: 'app-cases',
  templateUrl: './cases.component.html',
  styleUrls: ['./cases.component.css']
})

export class CasesComponent implements OnInit {

  constructor(
    private caseService: CaseService,
    private constantsService: ConstantsService,
    private router: Router
  ) { }

  @ViewChild('slickModal', { static: true }) slickModal: SlickCarouselComponent;
  @ViewChild('slickModal2', { static: true }) slickModal2: SlickCarouselComponent;

  loading: boolean = true;

  list_categories = []
  lists_cases = []
  lists_recommended = []
  list_backup = [];
  list_ramdom = [];

  categori_select = null;
  // lists = [
  //     {title: "Caso Quiz", slides: this.slides1},
  //     {title: "Categoria", slides: this.slides2},
  //     {title: "Categoria", slides: this.slides3}
  // ]

  color = ['rgba(0, 0, 0, 0.05)', 'white']

  ngOnInit(): void {
    this.getConstants()
    this.getCases()
    this.getRecommended()
  }

  next() {
    this.slickModal.slickNext()
  }

  back() {
    this.slickModal.slickPrev()
  }

  checkButtonCategory() {
    var windowWidth = window.innerWidth;
    if (windowWidth > 1150 && this.list_categories.length >= 4)
      return true
    else if (windowWidth > 850 && this.list_categories.length >= 3)
      return true
    else if (windowWidth > 650 && this.list_categories.length >= 2)
      return true
    else if (windowWidth < 650 && this.list_categories.length > 1)
      return true
    else
      return false
  }

  selectCategory(text) {
    var check = true;
    if (this.categori_select == text) {
      this.lists_cases = this.list_backup
      this.categori_select = null
    }
    else {
      this.categori_select = text;

      this.list_backup.forEach(el => {
        if (el.title == text && check) {

          check = false;
          this.lists_cases = []
          this.lists_cases.push(el)

          setTimeout(() => {
            this.scrollTo('case_select')
          }, 10);

        }
        else if (check) {
          this.lists_cases = []
        }
      });
    }

  }

  openRandom() {
    const random = Math.floor(Math.random() * this.list_ramdom.length);
    this.router.navigate(['/case/' + this.list_ramdom[random].id])
  }

  openCaseRandom(list) {
    if (list != null) {
      const random = Math.floor(Math.random() * list.slides.length);
      this.router.navigate(['/case/' + list.slides[random].id])
    }
    else {
      const random = Math.floor(Math.random() * this.lists_recommended.length);
      this.router.navigate(['/case/' + this.lists_recommended[random].id])
    }
  }

  async scrollTo(name) {
    var element = document.getElementById(name) as HTMLElement

    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
    const y = element.getBoundingClientRect().bottom + window.pageYOffset;

    if (supportsNativeSmoothScroll == true) {
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }

  checkButtonSlides(posi) {
    var windowWidth = window.innerWidth;
    if (windowWidth < 950 && this.lists_cases[posi].slides.length >= 3)
      return true
    else if (windowWidth < 513 && this.lists_cases[posi].slides.length >= 2)
      return true
    else if (windowWidth < 420 && this.lists_cases[posi].slides.length == 1)
      return true
    else
      return false
  }

  checkButtonRecommended() {
    var windowWidth = window.innerWidth;

    if (windowWidth < 950 && this.lists_recommended.length >= 3)
      return true
    else if (windowWidth < 513 && this.lists_recommended.length >= 2)
      return true
    else if (windowWidth < 420 && this.lists_recommended.length == 1)
      return true
    else
      return false
  }

  getConstants() {
    this.constantsService.getConstants().subscribe(data => {
      this.list_categories = data.categories
    }, error => { console.log(error) })
  }

  getRecommended() {
    this.constantsService.getRecommended().subscribe(data => {
      if (data.count > 0) {
        this.lists_recommended = data.results
      }
    }, error => { console.log(error) })
  }

  getCases() {
    this.loading = true;
    this.caseService.getCases().subscribe(data => {
      this.list_ramdom = data.results

      this.buildListCarousel(data)
      this.loading = false;
    }, error => { console.log(error) })
  }

  async buildListCarousel(obj) {

    obj.results.forEach(obj => {

      if (this.filter(obj.category_obj.title) < 0) {
        this.lists_cases.push({ title: obj.category_obj.title, slides: [obj] })
      }
      else {
        var posi = this.filter(obj.category_obj.title)
        this.lists_cases[posi].slides.push(obj)
      }
    });

    this.list_backup = this.lists_cases;
  }

  filter(value: string) {
    if (value != null && this.lists_cases != null) {
      let index = this.lists_cases.findIndex(el => el.title === value);
      return index
    }
  }

  slideConfigUsers = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "dots": false,
    "arrows": false,
    "infinite": false,
    "autoplay": false,
    "swipeToSlide": true,
    "autoplaySpeed": 3000,
    "responsive": [
      {
        "breakpoint": 950,
        "settings": {
          "slidesToShow": 3,
          "autoplaySpeed": 3000,
        }
      },
      {
        "breakpoint": 513,
        "settings": {
          "slidesToShow": 2,
          "autoplaySpeed": 3000,
        }
      },
      {
        "breakpoint": 420,
        "settings": {
          "slidesToShow": 1,
          "autoplaySpeed": 3000,
        }
      }
    ]
  };

  slideConfigCategory = {
    "slidesToShow": 5,
    "slidesToScroll": 1,
    "dots": false,
    "arrows": false,
    "infinite": false,
    "autoplay": false,
    "swipeToSlide": true,
    "autoplaySpeed": 3000,
    "responsive": [
      {
        "breakpoint": 1150,
        "settings": {
          "slidesToShow": 4,
          "autoplaySpeed": 3000,
        }
      },
      {
        "breakpoint": 850,
        "settings": {
          "slidesToShow": 3,
          "autoplaySpeed": 3000,
        }
      },
      {
        "breakpoint": 650,
        "settings": {
          "slidesToShow": 2,
          "autoplaySpeed": 3000,
        }
      }
    ]
  };

}
