import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../app.config';
import { Md5 } from "md5-typescript";
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})

export class AnswerCaseService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) { }

  getAnswerCase(id_case): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('Authorization', `token ${this.storageService.getToken()}`),
      params: new HttpParams()
        .set('case', id_case)
    };
    return this.http.get<any>(AppConfig.path + "core/answer-case/", options)
  }

  getAnswerCaseUser(id_user, text): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('Authorization', `token ${this.storageService.getToken()}`),
      params: new HttpParams()
        .set('user', id_user)
        .set('q', text)
    };
    return this.http.get<any>(AppConfig.path + "core/user/answer-user/", options)
  }

  postRespond(answer_case): Observable<any> {

    let body = new HttpParams()
      .set(`answer_case`, answer_case)

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('Authorization', `token ${this.storageService.getToken()}`)

    return this.http.post<any>(AppConfig.path + "core/case/respond/", body, { headers })
  }

}
